import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { ToastContainer } from "react-toastify";

import defaultValues from '../../constant/defaultValues';

import { useAuth } from '../../AuthProvider';
import { showErrorMessage, showSuccessMessage } from '../../utils/CustomNotification';

import { assignPlanToMember, FilterMembers } from '../../actions/memberAction';
import { hideLoading, showLoading } from '../../actions/loaderAction';

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from '../../components/InputField';
import SubHeader from '../../components/SubHeader';
import MemberFilter from '../../components/memberFilter/memberFilter';
import Emitter from '../../utils/Emitter';
import SmallPopup from '../../components/SmallPopup';
import DropDown from '../../components/DropDown';
import { getSubscriptionPlanList } from '../../actions/subscriptionPlanAction';
import { debounce, find, isEmpty, size } from 'lodash';


function Members(props) {

    const { admin, COMPANY_DEFAULTS } = defaultValues;
    const statusList = COMPANY_DEFAULTS.STATUS;
    const { user, isEndUser, isSuperAdmin } = useAuth();

    const [memberList, setMemberList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [searchTxt, setSearchTxt] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState(0);
    const [smallmodalShow, setSmallModalShow] = useState(false);
    const [planList, setPlanList] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [assignedMemberId, setAssignedMemberId] = useState(0);
    const [assignedPlanId, setAssignedPlanId] = useState(0);
    const initialRef = useRef(true);
    const [columnName, setColumnName] = useState("id");
    const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
    const [current_page, setCurrentPage] = useState(1);

    const [searchFilterObj, setFilterObj] = useState({
        filterBy: "hs_code",
        offset: admin.OFFSET,
        limit: admin.LIMIT,
        columnName: "id",
        sortDirection: admin.SORT_DIRECTION,
        filter_val: "",
        selected_companies: [],
        selected_hscodes: [],
        selected_services: [],
        selected_target_country: [],
        selected_origin_country: [],
        table_search: ""
    })

    const columns = [
        {
            name: 'Sr No',
            selector: (row, idx) => {
                return (
                    <>{offset + (idx + 1)}</>
                )
            },
            resizable: true,
            // width: "100px"
            width: "5%"
        },
        {
            name: 'Unique Number',
            selector: row => <div className='text-wrap'>{row.member_unique_number}</div>,
            resizable: true,
            // width: "150px"
            width: isSuperAdmin ? "13%" : "13%",
            // columnName: "member_unique_number",
            sortable: true
        },
        {
            name: 'Company Name',
            selector: row => <div className='tbl-text-wrap-row'>{row.company_name}</div>,
              width:"35%",
          //  width: "13.5%",
            // columnName: "company_name",
            sortable: true
        },
       // {
        //    name: 'Name',
         //   selector: row => <div className="tbl-text-wrap-row"> {(row.first_name ? row.first_name : '') + ` ` + (row.last_name ? row.last_name : '')}</div>,
         //   resizable: true,
          //  width: "13.5%",
           // sortable: true
        //},
        //{
          //  name: 'Email',
           // selector: row => <div className='tbl-text-wrap-row'>{row.email}</div>,
            //resizable: true,
           // width: isSuperAdmin ? "14%" : "18%",
           // sortable: true
        //},
        {
            name: 'Trade Mode',
            selector: row => <div className='text-wrap'>{row.shipment_mode}</div>,
            resizable: true,
            //width: "150px"
            width: isSuperAdmin ? "13%" : "13%",
            // columnName: "shipment_mode",
            sortable: true
        },
        {
            name: 'Country',
            selector: row => <div className='text-wrap'>{row.country_name}</div>,
            resizable: true,
            // width: "150px"
            width: isSuperAdmin ? "19%" : "19%",
            // columnName: "country_name",
            sortable: true
        },
        // {
        //     name: 'Plan Name',
        //     selector: row => <div className='text-wrap'>{(row.plan_name == null) ? "Free" : row.plan_name}</div>,
        //     resizable: true,
        //     width: "9%",
        //     omit: isSuperAdmin === false,
        //     sortable: true
        // },
        // {
        //     name: 'Status',
        //     selector: (row) => {
        //         const rowStatus = statusList.find(option => option.value === row.status);
        //         return rowStatus.label;
        //     },
        //     resizable: true,
        //     width: "8%",
        //     omit: isEndUser === true
        // },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="text-wrap">
                    {isSuperAdmin && <Link to={"/updateprofile/" + row.id}>
                        <button className="btn action-icon-btn m-0 p-0 " data-toggle="tooltip" data-placement="top" title="Edit">
                            <i className="fa-regular fa-pen-to-square icon-pen"></i>
                        </button>
                    </Link>
                    }

                    <Link to={"/company/" + row.id}>
                        <button className="btn action-icon-btn m-0 p-0 mx-2 " data-toggle="tooltip" data-placement="top" title="View">
                            <i className="fa-regular fa-eye icon-eyes"></i>
                        </button>
                    </Link>

                    {isSuperAdmin && row.status === 1 && <>
                        <button type="button" className="btn action-icon-btn m-0 p-0 " data-toggle="tooltip" data-placement="top" title="Upgrade Plan" onClick={() => handleAssignPlanClick(row)}>
                            <i className="fas fa-file-invoice-dollar icon-plan ms-1"></i>
                        </button>
                    </>
                    }

                    {/* 
                    <button className="btn" >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </button> */}
                </div>
            ),
            // resizable: true,
            // width: "100px"
            width: "10%"
        }
    ];

    const handleSort = async (column, sortDirection) => {
        if (column.name != undefined && column.name != "" && column.name != null) {
            setColumnName(column.name);
            setSortDirection(sortDirection);
            filterMemberData(
                admin.OFFSET,
                admin.LIMIT,
                current_page,
                column.name,
                sortDirection,
                searchTxt
            );
        }

    };

    const onSearchTextBoxChanged = debounce((e) => {
        console.log("Search Val === ", e.target.value);
        // setSearchTxt(e.target.value);
        let searchVal = e.target.value;
        //props.showLoading();
        if (searchVal.length >= 3 || searchVal.length == 0) {
            let sFilter = { ...searchFilterObj };
            sFilter.table_search = searchVal;
            setFilterObj(sFilter);
            setOffset(admin.OFFSET);
            setLimit(admin.LIMIT);
            setColumnName("id");
            setSortDirection(admin.SORT_DIRECTION);
            setSearchTxt(e.target.value);
            // getRegisteredMembers(admin.OFFSET, admin.LIMIT, e.target.value);
            setTimeout(async () => {
                await filterMemberData(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, searchVal);
                // props.hideLoading();
            }, 1500);

        }
    });

    /* const getRegisteredMembers = async (offset, limit, searchTxt) => {
        await props.showLoading();
        await props.getMembers(offset, limit, searchTxt).then((response) => {
            setMemberList(response.data);
            settotalRows(response.metadata.total);
        }).catch((err) => {
            console.log("Error ==== ", err);
            showErrorMessage("Something went wrong");
        });
        props.hideLoading();
    } */

    const filterMemberData = async (ofst = 0, lmt = 10, current_page = 1, columnName = "id", sortDirection = "DESC", searchval = "") => {
        // console.log("filterMemberData  function args === ", ofst, lmt);
        props.showLoading();
        let fObj = { ...searchFilterObj };
        if (fObj !== undefined && fObj !== 0 && fObj !== null && fObj !== "") {
            fObj.offset = ofst;
            fObj.limit = lmt;
            fObj.columnName = columnName;
            fObj.sortDirection = sortDirection;
            fObj.table_search = searchval;

            await props.FilterMembers(fObj).then((response) => {
                if (response) {
                    if (response.status === true) {
                        // console.log("filterMemberData Response of api in members page =========== ", response.data);
                        setMemberList(response.data);
                        settotalRows(response.metadata.total);
                    }
                }
            }).catch((err) => {
                console.log("Error ==== ", err);
            });

        }
        props.hideLoading();
    }

    const setMembersFilters = (newState) => {
        // console.log("newState =================== ", newState);

        newState.table_search = "";
        setFilterObj(newState);
    }

    const filterApplyOnMembers = (filterObj) => {
        // console.log("FilterObj == ", filterObj); // set obj in state
        // filterObj.table_search = "";
        // setFilterObj(filterObj);
        filterMemberData(admin.OFFSET, admin.LIMIT, current_page, "id", admin.SORT_DIRECTION, searchTxt);

    }

    const handleAssignPlanClick = (data) => {
        // console.log("member_id =========== ", data.id);
        setAssignedMemberId(data.id);
        setAssignedPlanId(data.plan_id);

        if (data.plan_id === null) {
            setSelectedPlan({ label: "Free", value: "" });
            // getPlans(0, 20, "");
        } else {
            // getPlans(0, 20, data.plan_name);
        }
        getPlans(0, 20, "");

        /* if (isEmpty(planList)) {
            getPlans(0, 2, "");
        } else {
            console.log("handleAssignPlanClick else ... ");
            if (data.plan_id === null) {
                setSelectedPlan({});
            } else {
                const s_plan = planList.find((option) => option.value === data.plan_id);
                if (!isEmpty(s_plan)) {
                    setSelectedPlan(s_plan);
                } else {
                    getPlans(0, 2, data.plan_name);
                }
            }


        }*/
        setSmallModalShow(true);
    }

    const handlePlanChange = (val) => {
        // console.log("handlePlanChange ========== ", val);
        setSelectedPlan(val);
        setAssignedPlanId(val.value);
    }

    const handlePlanInputChange = (val, actionMeta) => {
        // console.log("handlePlanInputChange ========= ", val);
        const { prevInputValue } = actionMeta;
        if (size(val) > 3) {
            getPlans(0, 20, val);
        } else if (size(val) === 0 && (prevInputValue !== val)) {
            getPlans(0, 20, "");
        }
    }

    const assignPlan = async (e) => {
        // console.log("Plan id ============= ", selectedPlan, assignedMemberId);
        e.preventDefault();
        const data = {
            plan_id: selectedPlan.value,
            member_id: assignedMemberId
        }
        await props.assignPlanToMember(data).then((response) => {
            // console.log("Response ==== ", response);
            if (response.status) {
                showSuccessMessage(response.message);
                setSmallModalShow(false);
                filterMemberData(admin.OFFSET, admin.LIMIT, current_page, "id", admin.SORT_DIRECTION, "");
            }
        }).catch((err) => {
            console.log("Error ==== ", err);
            showErrorMessage("Something went wrong!");
        })
    }

    const getPlans = async (offset, limit, searchTxt = "") => {
        await props.getSubscriptionPlans(offset, limit, "created_at", "DESC", searchTxt).then((response) => {
            if (response.status === true) {
                let pList = response.data.map((val) => {
                    return {
                        label: val.name,
                        value: val.id
                    }
                });
                let dList = [{
                    label: "Free",
                    value: ""
                }];
                let newPLList = [...dList, ...pList];
                setPlanList(newPLList);
            }
        }).catch((err) => {
            console.log("Error ==== ", err);
        })
    }

    useEffect(() => {
        if (assignedMemberId > 0) {

            const planOpt = find(planList, { value: assignedPlanId });

            if (planOpt) {
                setSelectedPlan(planOpt);
            }
        }
    }, [planList]);


    /*useEffect(() => {
        let searchVal = searchTxt;
        if (searchVal.length >= 3 || searchVal.length <= 0) {
            // setOffset(admin.OFFSET);
            // setLimit(admin.LIMIT);
            // setColumnName(admin.COLUMN_NAME);
            // setSortDirection(admin.SORT_DIRECTION);
            // let sFilter = { ...searchFilterObj };
            // sFilter.table_search = searchVal;
            // console.log('sFilter----sFilter----sFilter--', sFilter);
            // setFilterObj(sFilter);
            // getRegisteredMembers(admin.OFFSET, admin.LIMIT, e.target.value);
            // setTimeout(async() => {
                 filterMemberData(admin.OFFSET, admin.LIMIT, "id", admin.SORT_DIRECTION);
            // }, 1500)
        }
    },[searchTxt]);*/

    useEffect(() => {
        async function fetchData() {
            // You can await here
            // getRegisteredMembers(offset, limit, searchTxt);
            initialRef.current = false;
            await filterMemberData(admin.OFFSET, admin.LIMIT, current_page, "id", admin.SORT_DIRECTION, searchTxt);

        }
        if (initialRef.current === true) {
            fetchData();
        }

    }, []);

    useEffect(() => {
        // Emitter.on("FILTER_MEMBERS", filterMemberData);
        Emitter.on("FILTER_MEMBERS", filterApplyOnMembers);
        Emitter.on("SET_FILTER_MEMBERS", setMembersFilters);
        return () => {
            Emitter.off("FILTER_MEMBERS", filterApplyOnMembers);
        };
    });

   

    const expandedRowComponent = ({ data }) => {
        console.log(data);
        const rowStatus = statusList.find(option => option.value === data.status);
        const statusLabel = rowStatus ? rowStatus.label : '';
        return (

            <div style={{ padding: '20px', borderTop: '1px solid #ddd' }}>
                <div style={{
                    display: 'grid', 
                    gridTemplateColumns: '1fr 1fr 1fr', 
                    gap: '22px', 
                    padding: '12px', 
                    border: '1px solid #ddd', 
                    borderRadius: '8px'
                }}>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Name:</strong> {(data.salutation ? data.salutation : '') + ` ` + (data.first_name ? data.first_name : '') + ` ` + (data.last_name ? data.last_name : '')}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Email:</strong> {data.email || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Plan Name:</strong> {(data.plan_name == null) ? "Free" : data.plan_name}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Establish Date:</strong> {data.establish_date || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Status:</strong> {statusLabel}
                        </div>

                    </div>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Phone:</strong> {data.phone || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Country:</strong> {data.country_name || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Company Size:</strong> {data.company_size || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Trade Mode Type:</strong> {(data.trade_mode_type) && data.trade_mode_type === 'LOG_PTR' ? 'Logistic Partner' : data.trade_mode_type || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Website:</strong> {data.website || '-'}
                        </div>

                    </div>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Mobile:</strong> {data.mobile || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Designation:</strong> {data.designation || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>What You Want With Us:</strong> {data.with_us || '-'}
                        </div>

                       <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Reson:</strong> {data.reason || '-'}
                        </div>

                    </div>
                </div>
            </div>
        );
    };

    return (<>
        <div className='data-table-main'>

            <div className="AcSimpleTable">
                <SubHeader
                    layer1="Buyers/Suppliers Management"
                    layer2=""
                    layer3="List"
                    pageName="Buyers/Suppliers"
                    clickable={{
                        layer1: "",
                        layer2: "",
                        layer3: ""
                    }}
                />

                <div className='filter-section'>
                    <MemberFilter />
                </div>

                <div className='card1'>

                    <div className='table-header-name'>
                        <h5 className='table-text'>List</h5>
                        <div className='d-flex justify-content-between align-items-center p-3'>
                            <label className='mx-3'>Search</label>
                            <InputField
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                className='w-25 '
                                onKeyUp={onSearchTextBoxChanged}
                                // onChange={onSearchTextBoxChanged}
                                autoComplete="off"
                            ></InputField>
                        </div>

                    </div>

                    <div className="table-wrapper">
                        <div className="table-container" style={{ overflowX: 'auto', width: "100%" }}>
                            {memberList && (
                                <AcSimpleTable
                                    data={memberList}
                                    title=""
                                    pagination={true}
                                    paginationPageSize={limit}
                                    columns={columns}
                                    progressPending={loading}
                                    paginationTotalRows={totalRows}
                                    fixedColumnsStart={admin.FIXED_COLUMNS_START}
                                    getData={filterMemberData}
                                    offset={offset}
                                    limit={limit}
                                    columnName={columnName}
                                    sortDirection={sortDirection}
                                    setLimit={setLimit}
                                    setOffset={setOffset}
                                    // setColumnName={setColumnName}
                                    // setSortDirection={setSortDirection}
                                    setLoading={setLoading}
                                    paginationPerPage={limit}
                                    paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                                    // className="company-table"
                                    striped
                                    searchTxt={searchTxt}
                                    sortServer
                                    onSort={handleSort}
                                    defaultSortAsc={false}
                                    setCurrentPage={setCurrentPage}
                                    expandableRows
                                    expandableRowsComponent={expandedRowComponent}
                                />
                            )}
                        </div>
                    </div>

                </div>

                <SmallPopup
                    title="Assign Plan"
                    smallmodalShow={smallmodalShow}
                    setSmallModalShow={setSmallModalShow}
                    onSubmit={assignPlan}
                    closeButtonLabel="Cancel"
                    okButtonLabel="Assign"
                    className="btn-assign"
                >
                    <DropDown
                        options={planList}
                        placehoder="Select Plan"
                        onChange={handlePlanChange}
                        onInputChange={handlePlanInputChange}
                        name="plan_id"
                        value={selectedPlan}
                    ></DropDown>

                </SmallPopup>

            </div>
            <ToastContainer />
        </div>

    </>)
}

const mapStateToProps = state => {

    return {
        // member_list: state.member.memberlist,
        // authError: state.auth.errorMessages
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // getMembers: (offset, limit, searchTxt) => dispatch(getMemberList(offset, limit, searchTxt)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        FilterMembers: (filterData) => dispatch(FilterMembers(filterData)),
        getSubscriptionPlans: (offset, limit, columnName, sortDirection, searchTxt) =>
            dispatch(getSubscriptionPlanList(offset, limit, columnName, sortDirection, searchTxt)),
        assignPlanToMember: (data) => dispatch(assignPlanToMember(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
