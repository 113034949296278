import React, { useContext, useEffect, useRef, useState } from 'react';
import CheckBox from '../CheckBox'
import { useParams } from 'react-router-dom';
import FileUploadPDF from '../../pages/FileUploadPDF'
import axios from "axios";
import API_URL from "../../config/apiConfig";
import Choosefile from '../../components/Choosefile';
import inputicon from "../../images/Image _placeholder.svg"
import pdf from "../../images/sidebarImages/pdf_icon.svg"
import errorMessages from '../../constant/errorMessages';
import { connect } from 'react-redux';
import { addCertificateAction, getMemberCertificateAction, deleteCertificate, getOptionListAdmin, getCertificateSearchList, getSelectedCertificateListByIdList } from "../../actions/certificateAction";
import { size } from 'lodash';
import defaultValues from '../../constant/defaultValues';
import SmallPopup from "../../components/SmallPopup";
import MediumPopup from "../../components/MediumPopup";
import { showSuccessMessage, showErrorMessage } from '../../utils/CustomNotification';
import { Bounce, ToastContainer, toast } from "react-toastify";
import Button from '../../components/Button';

import { includes, isEmpty, isString, map, replace } from "lodash";
import { default as ReactSelect } from "react-select";
import { useAuth } from "../../AuthProvider";
import { hideLoading, showLoading } from "../../actions/loaderAction";

import { getMemberTradeMode } from "../../actions/updateProfileAction";
import ButtonAdminpanel from '../ButtonAdminpanel';

function Certificate({ addCertificates, name, ...props }) {
    const { isSuperAdmin, isEndUser } = useAuth();
    const { IMAGE_PATH } = defaultValues;
    const PATH = IMAGE_PATH.BASE_URL;
    const { id } = useParams();
    const [certificateLists, setCertificateList] = useState([]); // Business certificates 
    const [selectedCertificates, setSelectedCertificates] = useState({});
    const [formdata, setFormData] = useState({});
    const [formdata2, setFormData2] = useState({});
    const [verrors, setVErrors] = useState({});
    const [verrors2, setVErrors2] = useState({});
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const ALLOWED_TYPES = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [smallmodalShow, setSmallModalShow] = useState(false);

    const [mediummodalShow, setMediumModalShow] = useState(false);
    const [deletecertificateId, setDeleteCertiId] = useState();
    const [docImagePath, setDocImagePath] = useState();
    const [docPdfPath, setDocPdfPath] = useState();
    const [mediummodalShow2, setMediumModalShow2] = useState(false);

    const [optionCertiFicateList, setOptionCertiFicateList] = useState([]); // other certificates
    // const [initialOptionCertiFicateList, setInitialOptionCertiFicateList] = useState([]);
    const initialOptionCertiFicateList = useRef([]);
    const [certificate_admin, setCertificateAdmin] = useState([]);
    const [selectedCertificates2, setSelectedCertificates2] = useState({});

    const [otherCertisAvail, setOtherCertisAvail] = useState(false);
    var [isButtonDisabled, setisButtonDisabled] = useState(false);

    const [tradeModeType, setTradeModeData] = useState({});

    const fetchCertificateList = async () => {
        try {
            const response = await axios.get(API_URL + "/certificates");
            if (response.status === 200 && response.data.status === true) {
                const options = response.data.data;
                setCertificateList(options);
            }
        } catch (error) {
            console.error("Error fetching certificates:", error);
            showErrorMessage("Something went wrong");
        }
    };

    /* useEffect(() => {
        fetchCertificateList();
    }, []); */

    useEffect(() => {

        if (isEndUser) {
            const anyError = Object.values({ ...verrors }).some(err => err);
            setisButtonDisabled(anyError);
        } else {
            const hasErrors1 = Object.values({ ...verrors }).some(err => err);
            const hasErrors2 = Object.values({ ...verrors2 }).some(err => err);
            const disable = hasErrors1 || hasErrors2;
            setisButtonDisabled(disable);
        }

    }, [verrors, verrors2]);

    const handleCheckboxChange = (id) => {
        setSelectedCertificates((prev) => {
            const updated = {
                ...prev,
                [id]: !prev[id]
            };
            // console.log("Updated selectedCertificates:", updated);
            return updated;


        });
    };
    const style2 = {
        height: '50px',
        width: '50px',
    };
    const style3 = {
        height: '100%',
        width: '100%',
    };

    let pdf_close = document.getElementsByClassName("pdf-close")
    let img_section = document.getElementsByClassName("upload-img-error")

    const [filenamepdf, setFilenamepdf] = useState('');
    const [selectFileAddhar, setselectFileAddhar] = useState(inputicon);
    const [pdfIcon, setselectFilePDF] = useState(pdf);
    const [existingFiles, setExistingFiles] = useState({});
    const [existingFiles2, setExistingFiles2] = useState({});

    const [existingFiles3, setExistingFiles3] = useState({});
    const [file1, setFile] = useState({});
    const [file2, setFile2] = useState({});
    const handleChangePdf = (e) => {
        const selectedFilepdf = e.target.files[0];
        props.setFormData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.files[0]
            }
        });
        previewFilepdf(selectedFilepdf);
        if (selectedFilepdf) {
            props.setFilenamepdf(selectedFilepdf.name);
            previewFilepdf(selectedFilepdf);
            pdf_close[0].style.display = "block"
        }
    }

    const UploadAddhar = (e) => {
        const selectFileAddhar = e.target.files[0];
        if (selectFileAddhar) {

            setselectFileAddhar(selectFileAddhar.name);
            previewFilepdf(selectFileAddhar);
            setFilenamepdf(e.target.files[0].name)
            pdf_close[0].style.display = "block"
            img_section[0].style.opacity = "1"
        }
    }

    const previewFilepdf = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (file.type === "application/pdf") {
                setselectFileAddhar(pdf);
            } else {
                setselectFileAddhar(reader.result);
            }
        };
        reader.readAsDataURL(file);
    }

    const handleRemove = (id) => {
        setSmallModalShow(true);
        setDeleteCertiId(id);
    }

    const validate = async () => {
        const errors = {};
        const selectedIds = Object.keys(selectedCertificates).filter(id => selectedCertificates[id]);
        if (selectedIds.length === 0) {
            errors.certificateOption = errorMessages.FIELD_REQUIRED;
        }
        else {
            selectedIds.forEach(id => {
                const certificate = certificateLists.find(c => c.id === parseInt(id));
                const isAadhaarCard = certificate.slug === 'AADHAAR_CARD';
                if (isAadhaarCard) {
                    if (!formdata?.[`certificate_front#@${certificate.slug}`]) {
                        errors[`certificate_front#@${certificate.slug}`] = errorMessages.FIELD_REQUIRED;
                    }
                } else {
                    if (certificate && !formdata[`certificate#@${certificate.slug}`]) {
                        errors[`certificate#@${certificate.slug}`] = errorMessages.FIELD_REQUIRED;
                    }
                }
            });
        }
        setVErrors(errors);
        return Object.keys(errors).length > 0;
    };

    const validate2 = async () => {
        const errors = {};

        const selectedIds = Object.keys(selectedCertificates2).filter(id => selectedCertificates2[id]);

        if (selectedIds !== null) {

            selectedIds.forEach(id => {
                const certificate = optionCertiFicateList.find(c => c.value === parseInt(id));

                let isAadhaarCard = false;
                if (certificate) {
                    isAadhaarCard = certificate.slug === 'AADHAAR_CARD';
                }

                if (isAadhaarCard) {
                    if (!formdata2?.[`certificate_front#@${certificate.slug}`]) {
                        errors[`certificate_front#@${certificate.slug}`] = errorMessages.FIELD_REQUIRED;
                    }
                } else {
                    if (certificate && !formdata2[`certificate#@${certificate.slug}`]) {
                        errors[`certificate#@${certificate.slug}`] = errorMessages.FIELD_REQUIRED;
                    }
                }
            });
        }
        setVErrors2(errors);
        return Object.keys(errors).length > 0;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validateResult = await validate();
        let validate2Result = await validate2();
        if (!validateResult && !validate2Result) {
            props.showLoading();
            await addCertificates(id, formdata, formdata2).then((response) => {
                if (response.status === true) {
                    showSuccessMessage('Data Saved successfully...');
                    setTimeout(async () => {
                        for (var ckey in formdata) {
                            document.getElementById(ckey).value = null;
                        }
                        await fetchCertificateList();
                        setFormData({});
                        setFile({});
                        if (isSuperAdmin) {
                            for (var ckey2 in formdata2) {
                                if (document.getElementById(ckey2)) {
                                    document.getElementById(ckey2).value = null;
                                }

                            }
                            setFormData2({});
                            setFile2({});
                            setSelectedCertificates2({});
                            setExistingFiles2({});
                            await fetchCertificateAdmin();
                        }
                    }, 0);
                } else if (response.status === false) {
                    if (response.message === "Validation Errors") {
                        // let er = { ...verrors };
                        const result0 = Object.entries(response.error.requiredCertificates);
                        let newErrors = {};
                        result0.forEach(([key, value]) => {
                            key = replace(key, '.', '');
                            newErrors[key] = value[0];
                        });
                        setVErrors(newErrors);

                        const result1 = Object.entries(response.error.otherCertificates);
                        let newErrors2 = {};
                        result1.forEach(([key, value]) => {
                            key = replace(key, '.', '');
                            newErrors2[key] = value[0];
                        });
                        setVErrors2(newErrors2);
                    }
                } else {
                    showErrorMessage("Something went wrong");
                }
            }).catch((error) => {
                console.log(error);
                showErrorMessage("Something went wrong");
            });
            props.hideLoading();
        } else {
            console.log("Validation errors:", verrors);

        }
    };

    const handleChange = (e) => {
        const { name, files } = e.target;
        const [type, slug] = name.split("#@");

        let error = '';
        if (files.length > 0) {
            const file = files[0];

            if (!ALLOWED_TYPES.includes(file.type)) {
                // error = "Invalid file type. Only PDF, JPEG, and PNG are allowed."; // The file must be a file of type
                error = "The file must be a file of type jpg, png, jpeg or pdf";
            }
            else if (file.size > MAX_FILE_SIZE) {
                // error = "File size exceeds 2MB limit.";
                // console.log("File Size =============", file.size);
                error = "The file must be less than or equal to 2 MB";
            }


            if (slug === 'AADHAAR_CARD') {
                if (type === 'certificate_front') {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (error == "") {
                        setFile(prevFile => ({
                            ...prevFile,
                            [`certificate_front#@${slug}`]: {
                                path: URL.createObjectURL(file),
                                fileType: fileExtension,
                            },
                        }));
                    }

                }
                if (type === 'certificate_back') {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (error == "") {
                        setFile(prevFile => ({
                            ...prevFile,
                            [`certificate_back#@${slug}`]: {
                                path: URL.createObjectURL(file),
                                fileType: fileExtension,
                            },
                        }));
                    }

                }

            } else {

                const fileExtension = file.name.split('.').pop().toLowerCase();
                if (error == "") {
                    setFile(prevFile => ({
                        ...prevFile,
                        [`certificate#@${slug}`]: {
                            path: URL.createObjectURL(file),
                            fileType: fileExtension,
                        },
                    }));
                }


            }

            if (error) {
                setVErrors((prev) => ({
                    ...prev,
                    [name]: error,
                }));
                setFormData((prev) => ({ ...prev, [name]: null }));
            } else {
                setVErrors((prev) => ({
                    ...prev,
                    [name]: undefined,
                }));
                setFormData((prev) => ({
                    ...prev,
                    [name]: file,
                }));
                setExistingFiles((prevFile) => {
                    const file = files[0];
                    const isPDF = file.type === 'application/pdf';
                    
                    return {
                        ...prevFile,
                        [`certificate#@${slug}`]: {
                            path: isPDF ? pdfIcon : URL.createObjectURL(file),
                            file: file,
                        },
                    };
                });
                
            }
        } else {

            let tmpSlug = `certificate#@${slug}`;

            if (existingFiles[tmpSlug] === undefined || existingFiles[tmpSlug] === "" || existingFiles[tmpSlug] == null) {
                setFile(prevFile => ({
                    ...prevFile,
                    [`certificate#@${slug}`]: null,
                }));
                setFormData((prev) => ({
                    ...prev,
                    [name]: null,
                }));
            } else if (existingFiles[tmpSlug] !== undefined || existingFiles[tmpSlug] !== "" || existingFiles[tmpSlug] !== null) {

                let tmpPath = existingFiles[tmpSlug].path;
                let tmpArr = tmpPath.split("/");
                let fname = tmpArr[size(tmpArr) - 1];
                let fNameExt = fname.split(".")[1];
                setFile(prevFile => ({
                    ...prevFile,
                    [`certificate#@${slug}`]: {
                        path: fname,
                        fileType: fNameExt,
                    },
                }));
                setFormData((prev) => ({
                    ...prev,
                    [name]: fname,
                }));
            }

            // setExistingFiles((prevFile) => ({
            //     ...prevFile,
            //     [`certificate#@${slug}`]: {
            //         path: URL.createObjectURL(files[0]),
            //         file: files[0],
            //     },

            // }));

        }

        const hasErrors = Object.values({ ...verrors, [name]: error }).some(err => err);
        setSubmitDisabled(hasErrors);
    };

    const handleChange2 = (e) => {
       
        const { name, files } = e.target;
        const [type, slug] = name.split("#@");

        let error = '';
        if (files.length > 0) {
            const file = files[0];

            if (!ALLOWED_TYPES.includes(file.type)) {
                // error = "Invalid file type. Only PDF, JPEG, and PNG are allowed.";
                error = "The file must be a file of type jpg, png, jpeg or pdf";
            }
            else if (file.size > MAX_FILE_SIZE) {
                // error = "File size exceeds 2MB limit.";
                // console.log("File Size =============", file.size);
                error = "The file must be less than or equal to 2 MB";
            }

            if (slug === 'AADHAAR_CARD') {
                if (type === 'certificate_front') {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (error == "") {
                        setFile2(prevFile => ({
                            ...prevFile,
                            [`certificate_front#@${slug}`]: {
                                path: URL.createObjectURL(file),
                                fileType: fileExtension,
                            },
                        }));
                    }

                }
                if (type === 'certificate_back') {
                    const fileExtension = file.name.split('.').pop().toLowerCase();
                    if (error == "") {
                        setFile2(prevFile => ({
                            ...prevFile,
                            [`certificate_back#@${slug}`]: {
                                path: URL.createObjectURL(file),
                                fileType: fileExtension,
                            },
                        }));
                    }

                }

            } else {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                if (error == "") {
                    setFile2(prevFile => ({
                        ...prevFile,
                        [`certificate#@${slug}`]: {
                            path: URL.createObjectURL(file),
                            fileType: fileExtension,
                        },
                    }));
                }


            }

            if (error) {
                setVErrors2((prev) => ({
                    ...prev,
                    [name]: error,
                }));
                setFormData2((prev) => ({ ...prev, [name]: null }));
            } else {
                setVErrors2((prev) => ({
                    ...prev,
                    [name]: undefined,
                }));
                setFormData2((prev) => ({
                    ...prev,
                    [name]: file,
                }));

                setExistingFiles2((prevFile) => {
                    const file = files[0];
                    const isPDF = file.type === 'application/pdf';
                    
                    return {
                        ...prevFile,
                        [`certificate#@${slug}`]: {
                            path: isPDF ? pdfIcon : URL.createObjectURL(file),
                            file: file,
                        },
                    };
                });

            }
        } else {
            // setFormData2((prev) => ({
            //     ...prev,
            //     [name]: null,
            // }));

            let tmpSlug = `certificate#@${slug}`;
            if (slug === "AADHAAR_CARD") {
                if (type == 'certificate_front') {
                    tmpSlug = `certificate_front#@${slug}`;
                } else {
                    tmpSlug = `certificate_back#@${slug}`;
                }
            }

            // console.log("tmpSlug =========== ", tmpSlug);
            if (existingFiles2[tmpSlug] === undefined || existingFiles2[tmpSlug] === "" || existingFiles2[tmpSlug] == null) {
                // console.log("if at line no 476 .. ");
                if (slug === "AADHAAR_CARD") {
                    if (type == 'certificate_front') {
                        setFile2(prevFile => ({
                            ...prevFile,
                            [`certificate_front#@${slug}`]: null,
                        }));
                    }
                    else if (type == "certificate_back") {
                        setFile2(prevFile => ({
                            ...prevFile,
                            [`certificate_back#@${slug}`]: null,
                        }));
                    }

                    setFormData2((prev) => ({
                        ...prev,
                        [name]: null,
                    }));
                } else {
                    setFile2(prevFile => ({
                        ...prevFile,
                        [`certificate#@${slug}`]: null,
                    }));
                    setFormData2((prev) => ({
                        ...prev,
                        [name]: null,
                    }));
                }

            } else if (existingFiles2[tmpSlug] !== undefined || existingFiles2[tmpSlug] !== "" || existingFiles2[tmpSlug] !== null) {
                let tmpPath = existingFiles2[tmpSlug].path;
                // console.log(tmpPath.split("/"));
                let tmpArr = tmpPath.split("/");
                let fname = tmpArr[size(tmpArr) - 1];
                let fNameExt = fname.split(".")[1];

                if (slug === "AADHAAR_CARD") {
                    if (type == 'certificate_front') {

                        setFile2(prevFile => ({
                            ...prevFile,
                            [`certificate_front#@${slug}`]: {
                                path: fname,
                                fileType: fNameExt,
                            },
                        }));

                    }
                    else if (type == "certificate_back") {
                        setFile2(prevFile => ({
                            ...prevFile,
                            [`certificate_back#@${slug}`]: {
                                path: fname,
                                fileType: fNameExt,
                            },
                        }));
                    }

                    setFormData2((prev) => ({
                        ...prev,
                        [name]: fname,
                    }));
                } else {
                    setFile2(prevFile => ({
                        ...prevFile,
                        [`certificate#@${slug}`]: {
                            path: fname,
                            fileType: fNameExt,
                        },
                    }));
                    setFormData2((prev) => ({
                        ...prev,
                        [name]: fname,
                    }));
                }

            }
        }

        const hasErrors = Object.values({ ...verrors2, [name]: error }).some(err => err);
        setSubmitDisabled(hasErrors);
    };

    const styleError = {
        display: 'block',
    };

    const fetchMemberCertificate = async () => {
        try {
            const response = await props.getMemberCertificate(id);
            if (response && isEndUser) {
                setExistingFiles3(response.data);
            }
            const mCertificates = response.data.map((res) => {
                const cert = certificateLists.find((option) => option.id === res.certificate_id);
                if (cert) {
                    return {
                        ...cert,
                        fileData: res.fileData,
                        certificate_detail: res.certificate_detail,
                        certificate_type: res.certificate_type,
                        type: res.type,
                        mcId: res.id
                    };
                }
                return null;
            }).filter(Boolean);

            const updatedSelectedCertificates = {};
            const existingFilesMap = {
            };
            mCertificates.forEach(cert => {
                updatedSelectedCertificates[cert.id] = true;

                if (cert.slug === 'AADHAAR_CARD') {
                    if (cert.type === 'certificate_front') {
                        existingFilesMap[`certificate_front#@${cert.slug}`] = {
                            path: PATH + '/member_certificates/' + cert.certificate_detail,
                            mcId: cert.mcId
                        };

                        setFormData(prev => ({
                            ...prev,
                            [`certificate_front#@${cert.slug}`]: cert.certificate_detail
                        }));
                    }
                    if (cert.type === 'certificate_back') {

                        existingFilesMap[`certificate_back#@${cert.slug}`] = {
                            path: PATH + '/member_certificates/' + cert.certificate_detail,
                            mcId: cert.mcId
                        };

                        setFormData(prev => ({
                            ...prev,
                            [`certificate_back#@${cert.slug}`]: cert.certificate_detail
                        }));
                    }

                } else {

                    existingFilesMap[`certificate#@${cert.slug}`] = {
                        path: PATH + '/member_certificates/' + cert.certificate_detail,
                        mcId: cert.mcId
                    };

                    setFormData(prev => ({
                        ...prev,
                        [`certificate#@${cert.slug}`]: cert.certificate_detail
                    }));
                }

            });

            setSelectedCertificates(updatedSelectedCertificates);
            setExistingFiles(existingFilesMap);

            if (response) {
                let crList = response.data.map(res => res.certificate_id);

                if (!isEmpty(crList)) {
                    let updatedCrtiList = [];
                    let selectedCertiOptionList = [];
                    getSelectedCertList(crList).then((data) => {
                        if (!isEmpty(data)) {
                            data.forEach((cr) => {
                                updatedCrtiList.push(cr);
                                if (crList.includes(cr.value)) {
                                    selectedCertiOptionList.push(cr);
                                }
                            });

                            if (!isEmpty(selectedCertiOptionList)) {

                                const mCertificates2 = response.data.map((res) => {
                                    const cert2 = selectedCertiOptionList.find((option) => option.value === res.certificate_id);

                                    if (cert2) {
                                        return {
                                            ...cert2,
                                            fileData: res.fileData,
                                            certificate_detail: res.certificate_detail,
                                            certificate_type: res.certificate_type,
                                            type: res.type,
                                            mcId: res.id,

                                        };
                                    }
                                    return null;
                                }).filter(Boolean);


                                const updatedSelectedCertificates2 = {};
                                const existingFilesMap2 = [];

                                mCertificates2.forEach(cert2 => {
                                    updatedSelectedCertificates2[cert2.value] = true;
                                    if (cert2.slug === 'AADHAAR_CARD') {
                                        if (cert2.type === 'certificate_front') {
                                            existingFilesMap2[`certificate_front#@${cert2.slug}`] = {
                                                path: PATH + '/member_certificates/' + cert2.certificate_detail,
                                                mcId: cert2.mcId
                                            };

                                            setFormData2(prev => ({
                                                ...prev,
                                                [`certificate_front#@${cert2.slug}`]: cert2.certificate_detail
                                            }));
                                        }
                                        if (cert2.type === 'certificate_back') {

                                            existingFilesMap2[`certificate_back#@${cert2.slug}`] = {
                                                path: PATH + '/member_certificates/' + cert2.certificate_detail,
                                                mcId: cert2.mcId
                                            };

                                            setFormData2(prev => ({
                                                ...prev,
                                                [`certificate_back#@${cert2.slug}`]: cert2.certificate_detail
                                            }));
                                        }

                                    } else {
                                        existingFilesMap2[`certificate#@${cert2.slug}`] = {
                                            path: PATH + '/member_certificates/' + cert2.certificate_detail,
                                            mcId: cert2.mcId
                                        };

                                        setFormData2(prev => ({
                                            ...prev,
                                            [`certificate#@${cert2.slug}`]: cert2.certificate_detail
                                        }));
                                    }

                                });

                                setCertificateAdmin(selectedCertiOptionList);
                                setSelectedCertificates2(updatedSelectedCertificates2);
                                setExistingFiles2(existingFilesMap2);
                                setOptionCertiFicateList(updatedCrtiList);

                            }

                        }

                    })
                        .catch((err) => {
                            console.log("Error =", err);
                            showErrorMessage("Something went wrong");
                        });
                }

            };



        } catch (error) {
            console.error("Error fetching member certificates:", error);
            showErrorMessage("Something went wrong");
        }
    };

    const handleConfirmDeleteClick = async () => {
        //props.showLoading();
        await props
            .deleteCertificate(deletecertificateId)
            .then((response) => {
                // check selectedCertificates2 object
                setSmallModalShow(false);
                if (response.status === true) {
                    setVErrors2({});
                    //  props.hideLoading();
                    showSuccessMessage("Deleted successfully...");
                    setTimeout(async () => {

                        if (isSuperAdmin) {
                            let oldFiles = existingFiles2;
                            for (var key in existingFiles2) {
                                if (deletecertificateId === existingFiles2[key].mcId) {
                                    let x = [...certificate_admin];

                                    const [type, slug] = key.split("#@");

                                    if (slug !== "AADHAAR_CARD") {
                                        let res = x.filter((val) => val.slug !== slug);
                                        let deletedCertiOptionId = x.find((option) => option.slug === slug);


                                        if (deletedCertiOptionId) {
                                            let oldSelectedCertificates2 = { ...selectedCertificates2 };
                                            if (oldSelectedCertificates2[deletedCertiOptionId.value]) {
                                                delete oldSelectedCertificates2[deletedCertiOptionId.value];
                                                setSelectedCertificates2(oldSelectedCertificates2);
                                            }
                                        }

                                        setCertificateAdmin(res);
                                        delete oldFiles[key];


                                        let x2 = initialOptionCertiFicateList.current.find((val) => {
                                            if (val.slug === slug) {
                                                return true;
                                            }
                                        });

                                        if (!x2) {
                                            const updatedOptionList = optionCertiFicateList.filter(option => option.slug !== slug);
                                            setOptionCertiFicateList(updatedOptionList);
                                        } else {
                                            setOptionCertiFicateList(initialOptionCertiFicateList.current);
                                        }


                                    } else if (slug === "AADHAAR_CARD") {
                                        const res = x.filter((val) => val.slug !== slug);
                                        setCertificateAdmin(res);
                                        delete oldFiles[key];


                                        if (!oldFiles["certificate_front#@AADHAAR_CARD"] && !oldFiles["certificate_back#@AADHAAR_CARD"]) {
                                            const updatedAdmin = x.filter((val) => val.slug !== "AADHAAR_CARD");
                                            setCertificateAdmin(updatedAdmin);

                                            const updatedOptionList = optionCertiFicateList.filter(option => option.slug !== "AADHAAR_CARD");
                                            setOptionCertiFicateList(updatedOptionList);
                                        }


                                    }
                                }
                            }

                            setFormData2({});
                            setExistingFiles2(oldFiles);
                            await fetchMemberCertificate();
                            setFile2({});

                        }
                        setFormData({});
                        await fetchMemberCertificate();
                        setFile({});




                    }, 0);
                }

            })
            .catch((err) => {
                console.log("Error == ", err);
                showErrorMessage("Something went wrong");
            });
    };

    const handleImageShow = (path) => {
        setDocImagePath(path);
        setMediumModalShow(true);
    }

    const handlePdfShow = (path) => {
        setDocPdfPath(path);
        setMediumModalShow2(true);
    }

    useEffect(() => {
        if (size(certificateLists) > 0) {
            fetchMemberCertificate();
        }
    }, [certificateLists]);

    useEffect(() => {
        if (certificateLists && certificateLists.length > 0) {
            const defaultCertificates = certificateLists.reduce((acc, certificate) => {
                if (['BUSINESS_CERTI', 'TAX_CERTI'].includes(certificate.slug)) {
                    acc[certificate.id] = true;
                }
                return acc;
            }, {});

            setSelectedCertificates((prevState) => {
                const hasChanged = Object.keys(defaultCertificates).some(
                    (key) => defaultCertificates[key] !== prevState[key]
                );
                return hasChanged ? defaultCertificates : prevState;
            });
        }
    }, [{}]);

    const CustomOption = ({ innerRef, innerProps, isSelected, label }) => (
        <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" checked={isSelected} readOnly />
            <label style={{ marginLeft: '8px' }}>{label}</label>
        </div>
    );

    const fetchCertificateAdmin = async () => {
        let trade_mode = await props.getMemberTradeMode(id);
        await props.getCertificateAdmin(trade_mode)
            .then((response) => {
                if (response) {
                    const options = response.data.map((ct) => ({
                        value: ct.id,
                        label: ct.name,
                        slug: ct.slug
                    }));

                    setOptionCertiFicateList(options);
                    initialOptionCertiFicateList.current = options;
                }
            })
            .catch((err) => {
                console.log("Error =", err);
                showErrorMessage("Something went wrong");
            });
    };

    // useEffect(() => {
    //     const fetchData = async () => {
    //         await fetchCertificateList();
    //         await fetchCertificateAdmin();
    //     };
    //     fetchData();
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchCertificateList();
                if (isSuperAdmin) {

                    // setTimeout(async () => { await fetchCertificateAdmin(); }, 2000);
                    await fetchCertificateAdmin();

                }
            } catch (error) {
                console.error('Error fetching data:', error);
                showErrorMessage("Something went wrong");
            }
        };
        setTimeout(async () => { fetchData(); }, 2000);
    }, [isSuperAdmin]);




    // const handleCertificateChange = (selectedOptions) => {
    //     console.log("selectedOptions---",selectedOptions);
    //     setVErrors2({});

    //     selectedOptions.map((val) => {
    //         if(val.slug === "AADHAAR_CARD"){
    //         console.log("TOP ========",val);
    //         }
    //     });
    //     const updatedCertificates = selectedOptions.reduce((acc, option) => {
    //         acc[option.value] = option;
    //         return acc;
    //     }, {});

    //     setSelectedCertificates2(updatedCertificates);
    //     setCertificateAdmin(selectedOptions);
    //     console.log("000====",file2);
    //     let newfemdata = {};
    //     let newFile2 = { ...file2 };
    //     selectedOptions.map((val) => {
    //         if (val.slug === "AADHAAR_CARD") {
    //             if (formdata2[`certificate_front#@${val.slug}`] !== undefined) {
    //                 newfemdata[`certificate_front#@${val.slug}`] = formdata2[`certificate_front#@${val.slug}`];
    //             }
    //             if (formdata2[`certificate_back#@${val.slug}`] !== undefined) {
    //                 newfemdata[`certificate_back#@${val.slug}`] = formdata2[`certificate_back#@${val.slug}`];
    //             }
    //         } else {
    //             newfemdata[`certificate#@${val.slug}`] = formdata2[`certificate#@${val.slug}`];
    //         }


    //     });

    //     setFormData2(newfemdata);
    //     setFile2(newFile2);
    // }

    const handleCertificateChange = (selectedOptions) => {
        console.log("selectedOptions",selectedOptions);
        let errorsObj = { ...verrors2 };
        let newfemdata = {};
        let newFile2 = { ...file2 };
        selectedOptions.map((val) => {
            if (val.slug === "AADHAAR_CARD") {
                if (formdata2[`certificate_front#@${val.slug}`] !== undefined) {
                    newfemdata[`certificate_front#@${val.slug}`] = formdata2[`certificate_front#@${val.slug}`];
                }
                if (formdata2[`certificate_back#@${val.slug}`] !== undefined) {
                    newfemdata[`certificate_back#@${val.slug}`] = formdata2[`certificate_back#@${val.slug}`];
                }
            } else {
                console.log("Slug",val.slug);
                newfemdata[`certificate#@${val.slug}`] = formdata2[`certificate#@${val.slug}`];
            }
            if (file2[`certificate#@${val.slug}`]) {
                newFile2[`certificate#@${val.slug}`] = file2[`certificate#@${val.slug}`];
            }
            if (file2[`certificate_front#@${val.slug}`]) {
                newFile2[`certificate_front#@${val.slug}`] = file2[`certificate_front#@${val.slug}`];
            }
            if (file2[`certificate_back#@${val.slug}`]) {
                newFile2[`certificate_back#@${val.slug}`] = file2[`certificate_back#@${val.slug}`];
            }
        });

        Object.keys(file2).forEach((key) => {
            const certificateSlug = key.split("#@")[1];
            if (!selectedOptions.find((option) => option.slug === certificateSlug)) {
                delete newfemdata[key];
                delete newFile2[key];
            }
        });
        Object.keys(newfemdata).forEach((key) => {
            if (newfemdata[key] === undefined) {
                const existingFileKey = key;
                if (existingFiles2[existingFileKey]) {
                    const fileFromExisting = existingFiles2[existingFileKey];
                    const paths = fileFromExisting.path;
                    const fileName = paths.split('/').pop();
                    newfemdata[key] = fileName;
                    newFile2[key] = fileName;
        
                }
            }
        });

        const updatedCertificates = selectedOptions.reduce((acc, option) => {
            acc[option.value] = option;
            return acc;
        }, {});

        setSelectedCertificates2(updatedCertificates);
        setCertificateAdmin(selectedOptions);

        setFormData2(newfemdata);
        setFile2(newFile2);

        Object.keys(errorsObj).forEach((key) => {
            const certificateSlug = key.split("#@")[1];
            if (!selectedOptions.find((option) => option.slug === certificateSlug)) {
                delete errorsObj[key];
            }
        });

        setVErrors2(errorsObj);
    };



    const handleInputChange = (searchbox, x) => {
        if (searchbox !== '') {
            if (searchbox.length >= 3) {
                fetchBasedOnSearch(searchbox);
            }
        }
    }

    const fetchBasedOnSearch = async (searchbox = "") => {
        if (!isEmpty(searchbox)) {
            let trade_mode = await props.getMemberTradeMode(id);
            await props.getCertificateSearch(searchbox, trade_mode)
                .then((response) => {
                    if (response) {
                        const options = response.map((ct) => ({
                            value: ct.id,
                            label: ct.name,
                            slug: ct.slug
                        }));

                        const newList = [...optionCertiFicateList];

                        options.forEach(option => {
                            if (!newList.some(existing => existing.value === option.value)) {
                                newList.push(option);
                            }
                        });
                        setOptionCertiFicateList(newList);

                    }

                })
                .catch((err) => {
                    console.log("Error =", err);
                    showErrorMessage("Something went wrong");
                });

        }

    }

    const getSelectedCertList = async (ids) => {

        try {
            let trade_mode = await props.getMemberTradeMode(id);
            const response = await props.getSelectedCertificateListById(ids, trade_mode);

            if (response) {
                const options = response.map((ct) => ({
                    value: ct.id,
                    label: ct.name,
                    slug: ct.slug
                }));
                return options;
            } else {
                console.log("Response is not an array:", response);
                return [];
            }
        } catch (err) {
            console.log("Error:", err);
            showErrorMessage("Something went wrong");
            return [];
        }
    };


    const OtherCertificatesAvailable = () => {

        let x = [];
        if (existingFiles3 && Array.isArray(existingFiles3) && existingFiles3.length > 0) {
            x = existingFiles3
                .filter((data) => {
                    const certificateType = data.certificate_type?.trim().toUpperCase();
                    return certificateType !== "BUSINESS_CERTI" && certificateType !== "TAX_CERTI";
                });
        }

        if (x.length > 0) {
            setOtherCertisAvail(true);
        }

    }

    useEffect(() => {
        if (isEndUser) {
            OtherCertificatesAvailable();
        }
    }, [existingFiles3]);


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            // backgroundColor: 'lightblue', // Background color of the control
            // borderColor: state.isFocused ? 'blue' : 'gray', // Border color when focused/unfocused
            '&:hover': {
                // borderColor: 'green', // Border color on hover
            },
        }),

        // option: (provided, state) => ({
        //   ...provided,
        //   // backgroundColor: state.isSelected ? 'lightgreen' : 'white', // Background color when selected
        //   // color: state.isSelected ? 'black' : 'gray', // Text color when selected
        //   '&:hover': {
        //     backgroundColor: 'lightgray', // Hover color of options
        //   },
        // }),

        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#2b6491', // Background of the selected multi-value items
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff', // Color of the text inside the multi-value
            fontSize: "14px"
        }),

        multiValueRemove: (provided) => ({
            ...provided,
            color: '#df0024', // Remove button color
            ':hover': {
                backgroundColor: '#df0024', // Background color on hover for the remove button
            },
        }),
    };

    //console.log("certificate_admin",certificate_admin);

    return (
        <div>
            <h4 className='company-info-text'>Certificate</h4>

            <form id="account-detail" className=' p-4 pb-4 card-shadow bg-white rounded' onSubmit={handleSubmit}>
                <h4 className='head-office-text border-bottom upload-doc'>Upload Company Document</h4>
                <div className='certificate-main'>
                    {certificateLists.map((certificate) => (
                        <div key={certificate.id} className='certificate-child-first'>
                            <div className="certificate-child-secound">
                                <CheckBox
                                    checked={selectedCertificates[certificate.id] || ['BUSINESS_CERTI', 'TAX_CERTI'].includes(certificate.slug)}
                                    onChange={() => handleCheckboxChange(certificate.id)}
                                    disabled={['BUSINESS_CERTI', 'TAX_CERTI'].includes(certificate.slug) || props.isDisable || props.disableProfile}
                                ></CheckBox>
                                <p className="mx-1  cerificate-text">{certificate.name}</p>
                            </div>
                        </div>
                    ))
                    }
                </div>
                {certificateLists.map((certificate) => (
                    <div key={certificate.id} >
                        {selectedCertificates[certificate.id] && (
                            <>
                                {certificate.slug === 'AADHAAR_CARD' ? (
                                    <>
                                        <div className='upload-main border-top'>

                                            <div className='upload-content border-end mobile-view-border '>

                                                <div className='upload-input'>

                                                    <h4 className='upload-text'>Upload {certificate.name} Front Side <span className='text-danger'>*</span></h4>

                                                    <Choosefile
                                                        className={`form-control choose-pdf ${verrors[`certificate_front#@${certificate.slug}`] ? 'choose-pdf-error' : ''}`}
                                                        type="file"
                                                        accept="image/jpeg, image/png, image/jpg"
                                                        onChange={handleChange}
                                                        name={"certificate_front#@" + certificate.slug}
                                                        id={"certificate_front#@" + certificate.slug}

                                                    />

                                                    {verrors[`certificate_front#@${certificate.slug}`] && (
                                                        <p className="upload-faild-text my-1">
                                                            <span className="text-danger fw-bold">{verrors[`certificate_front#@${certificate.slug}`]}</span>
                                                        </p>
                                                    )}

                                                    <p className='upload-info'>Allowed PDF, JPEG, JPG or PNG. Max size of 2 MB</p>
                                                </div>

                                                <div className={`upload-img ${verrors[`certificate_front#@${certificate.slug}`] ? 'upload-img-error' : ''}`}>

                                                    {existingFiles[`certificate_front#@${certificate.slug}`] ? (
                                                        existingFiles[`certificate_front#@${certificate.slug}`].path.endsWith('.pdf') ? (
                                                            <img src={pdf} alt="PDF Icon" style={style2} id="pdf-img" onClick={() => handlePdfShow(existingFiles[`certificate_front#@${certificate.slug}`]?.path)} />
                                                        ) : (
                                                            <img src={existingFiles[`certificate_front#@${certificate.slug}`].path} alt="" style={style2} id="pdf-img" onClick={() => handleImageShow(existingFiles[`certificate_front#@${certificate.slug}`]?.path)} />
                                                        )
                                                    ) : file1[`certificate_front#@${certificate.slug}`] ? (
                                                        file1[`certificate_front#@${certificate.slug}`].fileType === 'pdf' ? (
                                                            <img
                                                                src={pdf}
                                                                alt='PDF'
                                                                style={style2}
                                                                id="pdf-img"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={file1[`certificate_front#@${certificate.slug}`].path}
                                                                alt='IMAGE'
                                                                style={style2}
                                                                id="pdf-img"
                                                            />
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <p className='upload-info'></p>

                                                    {!props.disableProfile && existingFiles[`certificate_front#@${certificate.slug}`]?.mcId && (
                                                        <button className='pdf-close' type='button' onClick={() => handleRemove(existingFiles[`certificate_front#@${certificate.slug}`]?.mcId)}>
                                                            <i className="fa-regular fa-trash-can text-danger"></i>
                                                        </button>
                                                    )}

                                                </div>

                                            </div>

                                            <div className='upload-content'>

                                                <div className='upload-input mobile-view-back'>
                                                    <h4 className='upload-text'>Upload {certificate.name} Back Side </h4>
                                                    <Choosefile
                                                        className={`form-control choose-pdf ${verrors[`certificate_back#@${certificate.slug}`] ? 'choose-pdf-error' : ''}`}
                                                        type="file"
                                                        accept="image/jpeg, image/png, image/jpg"
                                                        onChange={handleChange}
                                                        name={"certificate_back#@" + certificate.slug}
                                                        id={"certificate_back#@" + certificate.slug}
                                                    />
                                                    {verrors[`certificate_back#@${certificate.slug}`] && (
                                                        <p className="upload-faild-text my-1">
                                                            <span className="text-danger fw-bold">{verrors[`certificate_back#@${certificate.slug}`]}</span>
                                                        </p>
                                                    )}

                                                    <p className='upload-info'>Allowed PDF, JPEG, JPG or PNG. Max size of 2 MB</p>
                                                </div>

                                                <div className={`upload-img ${verrors[`certificate_back#@${certificate.slug}`] ? 'upload-img-error' : ''}`}>

                                                    {existingFiles[`certificate_back#@${certificate.slug}`] ? (
                                                        existingFiles[`certificate_back#@${certificate.slug}`].path.endsWith('.pdf') ? (
                                                            <img src={pdf} alt="PDF Icon" style={style2} id="pdf-img" onClick={() => handlePdfShow(existingFiles[`certificate_back#@${certificate.slug}`]?.path)} />
                                                        ) : (
                                                            <img src={existingFiles[`certificate_back#@${certificate.slug}`].path} alt="" style={style2} id="pdf-img" onClick={() => handleImageShow(existingFiles[`certificate_back#@${certificate.slug}`]?.path)} />
                                                        )
                                                    ) : file1[`certificate_back#@${certificate.slug}`] ? (
                                                        file1[`certificate_back#@${certificate.slug}`].fileType === 'pdf' ? (
                                                            <img
                                                                src={pdf}
                                                                alt='PDF'
                                                                style={style2}
                                                                id="pdf-img"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={file1[`certificate_back#@${certificate.slug}`].path}
                                                                alt='IMAGE'
                                                                style={style2}
                                                                id="pdf-img"
                                                            />
                                                        )
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <p className='upload-info'>{filenamepdf}</p>

                                                    {!props.disableProfile && existingFiles[`certificate_back#@${certificate.slug}`]?.mcId && (
                                                        <button className='pdf-close' type='button' onClick={() => handleRemove(existingFiles[`certificate_back#@${certificate.slug}`]?.mcId)}>
                                                            <i className="fa-regular fa-trash-can text-danger"></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) :

                                    (
                                        <div>
                                            <div className='upload-main border-top'>

                                                <div className='upload-content '>

                                                    <div className='upload-input'>

                                                        <h4 className='upload-text'>Upload {certificate.name} <span className='text-danger'>*</span></h4>

                                                        <Choosefile
                                                            className={`form-control choose-pdf ${verrors[`certificate#@${certificate.slug}`] ? 'choose-pdf-error' : ''}`}
                                                            type="file"
                                                            accept="image/jpeg, image/png, image/jpg"
                                                            onChange={handleChange}
                                                            name={"certificate#@" + certificate.slug}
                                                            disabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                                                            id={"certificate#@" + certificate.slug}
                                                        />

                                                        {verrors[`certificate#@${certificate.slug}`] && (
                                                            <p className="upload-faild-text my-1">
                                                                <span className="certificate-required">{verrors[`certificate#@${certificate.slug}`]}</span>
                                                            </p>
                                                        )}
                                                        <p className='upload-info'>Allowed PDF, JPEG, JPG or PNG. Max size of 2 MB</p>


                                                    </div>

                                                    <div className={`upload-img ${verrors[`certificate#@${certificate.slug}`] ? 'upload-img-error' : ''} `}>

                                                        <div className={`position-relative ${props.isDisable || props.disableProfile ? ` ` : `certificate-img-hover`} `}>

                                                            {existingFiles[`certificate#@${certificate.slug}`] ? (
                                                                existingFiles[`certificate#@${certificate.slug}`].path.endsWith('.pdf') ? (
                                                                    <img src={pdf} alt="PDF Icon" style={style2} id="pdf-img" onClick={() => handlePdfShow(existingFiles[`certificate#@${certificate.slug}`]?.path)} className='line-964' />
                                                                ) : (
                                                                    <img src={existingFiles[`certificate#@${certificate.slug}`].path} alt="" style={style2} id="pdf-img" onClick={() => handleImageShow(existingFiles[`certificate#@${certificate.slug}`]?.path)} className='line-966' />
                                                                )
                                                            ) : file1[`certificate#@${certificate.slug}`] ? (
                                                                file1[`certificate#@${certificate.slug}`].fileType === 'pdf' ? (
                                                                    <img
                                                                        src={pdf}
                                                                        alt='PDF'
                                                                        style={style2}
                                                                        id="pdf-img"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={file1[`certificate#@${certificate.slug}`].path}
                                                                        alt='IMAGE'
                                                                        style={style2}
                                                                        id="pdf-img"
                                                                        className='line-982'
                                                                    />
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}

                                                            <p className='upload-info'>{filenamepdf}</p>

                                                            {!props.disableProfile && existingFiles[`certificate#@${certificate.slug}`]?.mcId && (
                                                                <button className='pdf-close' type='button' onClick={() => handleRemove(existingFiles[`certificate#@${certificate.slug}`]?.mcId)} disabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}>
                                                                    <i className="fa-regular fa-trash-can text-danger"></i>
                                                                </button>
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    )}
                            </>
                        )}

                    </div>

                )


                )}

                {
                    isSuperAdmin && (
                        <div>
                            <div className='upload-main border-top'>
                                <div className='upload-content '>
                                    <div className='upload-input'>
                                        {/* <h3>Other certificates</h3> */}
                                        <div className=''>

                                            <h4 className='upload-text upload-doc'>Other Certificates</h4>
                                        </div>
                                        <ReactSelect
                                            options={optionCertiFicateList}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={true}
                                            onChange={handleCertificateChange}
                                            value={certificate_admin}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onInputChange={handleInputChange}
                                            components={{ Option: CustomOption }}
                                            removeSelected={false}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>

                            {optionCertiFicateList.map((certificate) => (
                                <div key={certificate.value}>

                                    {selectedCertificates2[certificate.value] && (
                                        <>
                                            {certificate.slug === 'AADHAAR_CARD' ? (
                                                <>
                                                    <div className='upload-main border-top'>
                                                        <div className='upload-content border-end mobile-view-border '>
                                                            <div className='upload-input'>
                                                                <h4 className='upload-text'>Upload {certificate.label} Front Side <span className='text-danger'>*</span></h4>
                                                                <Choosefile
                                                                    className={`form-control choose-pdf ${verrors2[`certificate_front#@${certificate.slug}`] ? 'choose-pdf-error' : ''}`}
                                                                    type="file"
                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                    onChange={handleChange2}
                                                                    name={"certificate_front#@" + certificate.slug}
                                                                    id={"certificate_front#@" + certificate.slug}
                                                                />

                                                                {verrors2[`certificate_front#@${certificate.slug}`] && (
                                                                    <p className="upload-faild-text my-1">
                                                                        <span className="text-danger fw-bold">{verrors2[`certificate_front#@${certificate.slug}`]}</span>
                                                                    </p>
                                                                )}
                                                                <p className='upload-info'>Allowed PDF, JPEG, JPG or PNG. Max size of 2 MB</p>
                                                            </div>

                                                            <div className={`upload-img ${verrors2[`certificate_front#@${certificate.slug}`] ? 'upload-img-error' : ''}`}>


                                                                <div className='position-relative certificate-img-hover'>
                                                                    {existingFiles2[`certificate_front#@${certificate.slug}`] ? (
                                                                        existingFiles2[`certificate_front#@${certificate.slug}`].path.endsWith('.pdf') ? (
                                                                            <img src={pdf} alt="PDF Icon" style={style2} id="pdf-img" onClick={() => handlePdfShow(existingFiles2[`certificate_front#@${certificate.slug}`]?.path)} />
                                                                        ) : (
                                                                            <img src={existingFiles2[`certificate_front#@${certificate.slug}`].path} alt="" style={style2} id="pdf-img" onClick={() => handleImageShow(existingFiles2[`certificate_front#@${certificate.slug}`]?.path)} />
                                                                        )
                                                                    ) : file2[`certificate_front#@${certificate.slug}`] ? (
                                                                        file2[`certificate_front#@${certificate.slug}`].fileType === 'pdf' ? (
                                                                            <img
                                                                                src={pdf}
                                                                                alt='PDF'
                                                                                style={style2}
                                                                                id="pdf-img"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={file2[`certificate_front#@${certificate.slug}`].path}
                                                                                alt='IMAGE'
                                                                                style={style2}
                                                                                id="pdf-img"
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <p className='upload-info'></p>

                                                                    {existingFiles2[`certificate_front#@${certificate.slug}`]?.mcId && (
                                                                        <button className='pdf-close' type='button' onClick={() => handleRemove(existingFiles2[`certificate_front#@${certificate.slug}`]?.mcId)}>
                                                                            <i className="fa-regular fa-trash-can text-danger"></i>
                                                                        </button>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className='upload-content upload-back'>

                                                            <div className='upload-input mobile-view-back'>
                                                                <h4 className='upload-text'>Upload {certificate.label} Back Side </h4>
                                                                <Choosefile
                                                                    className={`form-control choose-pdf ${verrors2[`certificate_back#@${certificate.slug}`] ? 'choose-pdf-error' : ''}`}
                                                                    type="file"
                                                                    accept="image/jpeg, image/png, image/jpg"
                                                                    onChange={handleChange2}
                                                                    name={"certificate_back#@" + certificate.slug}
                                                                    id={"certificate_back#@" + certificate.slug}
                                                                />
                                                                {verrors2[`certificate_back#@${certificate.slug}`] && (
                                                                    <p className="upload-faild-text my-1">
                                                                        <span className="text-danger fw-bold">{verrors2[`certificate_back#@${certificate.slug}`]}</span>
                                                                    </p>
                                                                )}
                                                                <p className='upload-info'>Allowed PDF, JPEG, JPG or PNG. Max size of 2 MB</p>
                                                            </div>

                                                            <div className={`upload-img ${verrors2[`certificate_back#@${certificate.slug}`] ? 'upload-img-error' : ''}`}>

                                                                <div className='position-relative certificate-img-hover'>
                                                                    {existingFiles2[`certificate_back#@${certificate.slug}`] ? (
                                                                        existingFiles2[`certificate_back#@${certificate.slug}`].path.endsWith('.pdf') ? (
                                                                            <img src={pdf} alt="PDF Icon" style={style2} id="pdf-img" onClick={() => handlePdfShow(existingFiles2[`certificate_back#@${certificate.slug}`]?.path)} />
                                                                        ) : (
                                                                            <img src={existingFiles2[`certificate_back#@${certificate.slug}`].path} alt="" style={style2} id="pdf-img" onClick={() => handleImageShow(existingFiles2[`certificate_back#@${certificate.slug}`]?.path)} />
                                                                        )
                                                                    ) : file2[`certificate_back#@${certificate.slug}`] ? (
                                                                        file2[`certificate_back#@${certificate.slug}`].fileType === 'pdf' ? (
                                                                            <img
                                                                                src={pdf}
                                                                                alt='PDF'
                                                                                style={style2}
                                                                                id="pdf-img"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={file2[`certificate_back#@${certificate.slug}`].path}
                                                                                alt='IMAGE'
                                                                                style={style2}
                                                                                id="pdf-img"
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    <p className='upload-info'>{filenamepdf}</p>

                                                                    {existingFiles2[`certificate_back#@${certificate.slug}`]?.mcId && (
                                                                        <button className='pdf-close' type='button' onClick={() => handleRemove(existingFiles2[`certificate_back#@${certificate.slug}`]?.mcId)}>
                                                                            <i className="fa-regular fa-trash-can text-danger"></i>
                                                                        </button>
                                                                    )}


                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </>
                                            ) :
                                                (
                                                    <div>
                                                        <div className='upload-main border-top'>

                                                            <div className='upload-content '>

                                                                <div className='upload-input'>

                                                                    <h4 className='upload-text'>Upload {certificate.label} <span className='text-danger'>*</span></h4>

                                                                    <Choosefile
                                                                        className={`form-control choose-pdf ${verrors2[`certificate#@${certificate.slug}`] ? 'choose-pdf-error' : ''}`}
                                                                        type="file"
                                                                        accept="image/jpeg, image/png, image/jpg"
                                                                        onChange={handleChange2}
                                                                        name={"certificate#@" + certificate.slug}
                                                                        id={"certificate#@" + certificate.slug}
                                                                    />

                                                                    {verrors2[`certificate#@${certificate.slug}`] && (
                                                                        <p className="upload-faild-text my-1">
                                                                            <span className="text-danger fw-bold">{verrors2[`certificate#@${certificate.slug}`]}</span>
                                                                        </p>
                                                                    )}
                                                                    <p className='upload-info'>Allowed PDF, JPEG, JPG or PNG. Max size of 2 MB</p>


                                                                </div>

                                                                <div className={`upload-img ${verrors2[`certificate#@${certificate.slug}`] ? 'upload-img-error' : ''}`}>

                                                                    <div className='position-relative certificate-img-hover'>
                                                                        {existingFiles2[`certificate#@${certificate.slug}`] ? (
                                                                            existingFiles2[`certificate#@${certificate.slug}`].path.endsWith('.pdf') ? (
                                                                                <img src={pdf} alt="PDF Icon" style={style2} id="pdf-img" onClick={() => handlePdfShow(existingFiles2[`certificate#@${certificate.slug}`]?.path)} />
                                                                            ) : (
                                                                                <img src={existingFiles2[`certificate#@${certificate.slug}`].path} alt="" style={style2} id="pdf-img 5" onClick={() => handleImageShow(existingFiles2[`certificate#@${certificate.slug}`]?.path)} />
                                                                            )
                                                                        ) : file2[`certificate#@${certificate.slug}`] ? (
                                                                            file2[`certificate#@${certificate.slug}`].fileType === 'pdf' ? (
                                                                                <img
                                                                                    src={pdf}
                                                                                    alt='PDF'
                                                                                    style={style2}
                                                                                    id="pdf-img"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={file2[`certificate#@${certificate.slug}`].path}
                                                                                    alt='IMAGE'
                                                                                    style={style2}
                                                                                    id="pdf-img 2"
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        <p className='upload-info'></p>

                                                                        {existingFiles2[`certificate#@${certificate.slug}`]?.mcId && (
                                                                            <button className='pdf-close' type='button' onClick={() => handleRemove(existingFiles2[`certificate#@${certificate.slug}`]?.mcId)}>
                                                                                <i className="fa-regular fa-trash-can text-danger"></i>
                                                                            </button>
                                                                        )}
                                                                    </div>

                                                                </div>


                                                            </div>

                                                        </div>
                                                    </div>
                                                )}

                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    )
                }

                {
                    isEndUser && (
                        <>
                            <div className='border-top other-certificate-main'>

                                <h4 className='upload-text'>Other Certificates</h4>

                                <div className="certificate-main">
                                    {otherCertisAvail && existingFiles3.length > 0 ? (
                                        existingFiles3
                                            .filter((data) => {
                                                const certificateType = data.certificate_type?.trim().toUpperCase();
                                                return certificateType !== "BUSINESS_CERTI" && certificateType !== "TAX_CERTI";
                                            })
                                            .map((data) => (
                                                <div>

                                                    <div key={data.id} className="certificate-item">

                                                        {data.certificate_detail ? (
                                                            data.certificate_detail.endsWith('.pdf') ? (
                                                                <img
                                                                    src={pdf}
                                                                    alt="PDF Icon"
                                                                    style={style2}
                                                                    id="pdf-img"
                                                                    onClick={() => handlePdfShow(PATH + '/member_certificates/' + data.certificate_detail)}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={PATH + '/member_certificates/' + data.certificate_detail}
                                                                    alt="Certificate"
                                                                    style={style2}
                                                                    id="pdf-img"
                                                                    onClick={() => handleImageShow(PATH + '/member_certificates/' + data.certificate_detail)}
                                                                />
                                                            )
                                                        ) : (
                                                            <p className='py-2 '>If you would like to add additional certificates, please email us at <a href="mailto:{process.env.REACT_APP_SUPPORT_MAIL}">{process.env.REACT_APP_SUPPORT_MAIL}.</a></p>
                                                        )}

                                                        <div className='mt-1'>
                                                            {
                                                                data.type !== 'certificate' ? (
                                                                    <p>
                                                                        {data.certificates?.name || 'Unnamed Certificate'}
                                                                        {data.type === 'certificate_front' ? ' front ' : ' back '}
                                                                    </p>
                                                                ) : (
                                                                    <p>{data.certificates?.name || 'Unnamed Certificate'}</p>
                                                                )
                                                            }
                                                        </div>

                                                    </div>

                                                </div>

                                            ))
                                    ) : (
                                        <p>If you would like to add additional certificates, please email us at <a href="mailto:{process.env.REACT_APP_SUPPORT_MAIL}">{process.env.REACT_APP_SUPPORT_MAIL}.</a></p>
                                    )}
                                </div>
                            </div>
                        </>
                    )
                }

                {verrors.certificateOption && <span className="company-required" style={styleError}>{verrors.certificateOption}</span>}
                {!props.disableProfile && (
                    <div className="profile-btn border-top">
                        <ButtonAdminpanel className="mt-4" type="submit" disabled={isSuperAdmin ? isButtonDisabled : (props.isDisable || props.disableProfile || isButtonDisabled)}>
                            Save Changes
                        </ButtonAdminpanel>
                    </div>
                )}
                {/* <div className="profile-btn border-top">
                    <Button className="btn-regi mt-4" type="submit" disabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}>
                        Save Changes
                    </Button>
                </div> */}
            </form>
            {/* <ToastContainer /> */}

            <SmallPopup
                title="Delete Certificate"
                smallmodalShow={smallmodalShow}
                setSmallModalShow={setSmallModalShow}
                onSubmit={handleConfirmDeleteClick}
                closeButtonLabel="Cancel"
                okButtonLabel="Delete"
            >
                <div className='row my-3'>
                    <div className="col-md-12 p-0">Are you sure you want to delete?</div>
                </div>

            </SmallPopup>

            <MediumPopup
                title="Certificate"
                mediummodalShow={mediummodalShow}
                setMediumModalShow={setMediumModalShow}
                closeButtonLabel="Cancel"
            >
                <div className='row my-3'>
                    <div className="col-md-12 p-0">
                        <img src={docImagePath} alt="Placeholder" style={style3} id="pdf-img" />
                    </div>
                </div>

            </MediumPopup>

            <MediumPopup
                title="Certificate"
                mediummodalShow={mediummodalShow2}
                setMediumModalShow={setMediumModalShow2}
                closeButtonLabel="Cancel"
            >
                <div className='row my-3'>
                    <div className="col-md-12 p-0" style={{ height: "500px" }}>
                        <embed src={`${docPdfPath}#toolbar=0`} type='application/pdf' style={style3} id="pdf-img" />
                    </div>
                </div>
            </MediumPopup>

        </div>
    )
}
const mapStateToProps = state => {
    return {
        getMemberCertificate: state.update.getMemberCertificate,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getMemberTradeMode: (member_id) => dispatch(getMemberTradeMode(member_id)),
        getCertificateAdmin: (trade_mode) => dispatch(getOptionListAdmin(trade_mode)),
        getCertificateSearch: (searctText, trade_mode) => dispatch(getCertificateSearchList(searctText, trade_mode)),
        getSelectedCertificateListById: (ids, trade_mode) => dispatch(getSelectedCertificateListByIdList(ids, trade_mode)),
        addCertificates: (id, data, data2) => dispatch(addCertificateAction(id, data, data2)),
        getMemberCertificate: (member_id) =>
            dispatch(getMemberCertificateAction(member_id)),
        deleteCertificate: (id) => dispatch(deleteCertificate(id)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
