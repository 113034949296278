import React, { useEffect, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { viewProfileAction } from '../../actions/viewProfileAction';

import CompanyInfo from '../../components/ViewProfile/CompanyInfo';
import ServiceInfo from '../../components/ViewProfile/ServiceInfo';
import CertificatesInfo from '../../components/ViewProfile/CertificatesInfo';
import AboutCompany from '../../components/ViewProfile/AboutCompany';
import ContactDetail from '../../components/ViewProfile/ContactDetail';
import LocationDetail from '../../components/ViewProfile/LocationDetail';

import companyDetails from "../../images/company_details.svg";
import companyInoiconimg from "../../images/images&video.svg";
import companyInoicon from "../../images/company-details-icon.svg";
import companyLocation from "../../images/company_locations.svg";
import CompanyInfoIcon from "../../images/company-info-icon.svg"

import NotVeryfied from "../../images/This company is NOT VERIFIED (1).png"
import NotVeryfiedots from "../../images/notveryfive_dots.svg"


import { isEmpty } from 'lodash';
import CompanyImageVideo from '../../components/ViewProfile/CompanyImageVideo';
import SubHeader from '../../components/SubHeader';
import { hideLoading, showLoading } from '../../actions/loaderAction';
import { ToastContainer } from "react-toastify";
import { showErrorMessage } from '../../utils/CustomNotification';
import { useAuth } from '../../AuthProvider';
import { MENU_ROUTES } from '../../config/menuRoutes';

function ViewProfile(props) {

  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isEndUser, isSuperAdmin, memberCompanyID } = useAuth();

  const [cr, setCR] = useState(null);
  const [memberHeadAddress, setMemberHeadAddress] = useState(null);
  const [memberBranchAddress, setMemberBranchAddress] = useState(null);
  const [memberCertificates, setMemberCertificates] = useState(null);
  const [memberContacts, setMemberContacts] = useState(null);
  const [memberServices, setMemberServices] = useState(null);
  const [memberdetail, setMemberDetail] = useState(null);
  const [memberHsCode, setMemberHsCode] = useState(null);
  const [memberTargetCountries, setMemberTargetCountries] = useState(null);
  const [cInfo, setcInfo] = useState(null);
  const [mediaDetails, setMediaDetails] = useState(null);
  const [showMediaSection, setShowMediaSection] = useState(false);
  const [showOtherSection, setShowOtherSection] = useState(false);
  const initialRef = useRef(true);
  const tradeModeRef = useRef(null);

  const fetchCompanyProfile = async (id) => {
    await props.showLoading();
    await props.viewCompany(id).then((response) => {


      if (response.status) {

        if (!isEmpty(response.data)) {

          const { branch_office_address, head_office_address, hs_codes, member_certificates, verified_member_certificates, member_contacts, member_services, memberdetail, social_medias, videos, images, trade_countries } = response.data[0];


          const companyRepresentative = {
            first_name: response.data[0].first_name,
            last_name: response.data[0].last_name,
            email: response.data[0].email,
            designation: response.data[0].designation,
            country: (!isEmpty(response.data[0].country)) ? response.data[0].country.name : '',
            city: (!isEmpty(response.data[0].member_city)) ? response.data[0].member_city.name : '',
            phone: response.data[0].phone,
            mobile: response.data[0].mobile,
            company_representative_image: response.data[0].company_representative_image
          }

          const companyInformation = {
            company_name: response.data[0].company_name,
            company_logo: response.data[0].company_logo,
            company_size: response.data[0].company_size,
            shipment_mode: response.data[0].shipment_mode,
            trade_mode_type: response.data[0].trade_mode_type,
            website: response.data[0].website,
            industry: response.data[0].member_industry.industy_name,
            country: (!isEmpty(response.data[0].country)) ? response.data[0].country.name : '',
            city: (!isEmpty(response.data[0].member_city)) ? response.data[0].member_city.name : '',
            status: response.data[0].status,
            est_dt: (response.data[0].establish_date) ? response.data[0].establish_date : '',
            // verfied_company: (response.data[0].notVerified > 0 || (response.data[0].notVerified === 0 && isEmpty(member_certificates))) ? false : true,
            social_medias: social_medias,
            orexID: response.data[0].member_unique_number


          }


          const companyVideos = {
            videos: videos,
            images: images
          }

          if (isEmpty(videos) && isEmpty(images)) {
            setShowMediaSection(false);
          } else {
            setShowMediaSection(true);
          }

          setcInfo(companyInformation);
          setMediaDetails(companyVideos);


          if (!isEmpty(head_office_address)) {
            setMemberHeadAddress(head_office_address[0]);
          } else {
            let tmpHeadOffice = {
              address1: null,
              address2: null,
              city: null,
              country: (!isEmpty(response.data[0].head_office_country)) ? response.data[0].head_office_country : '',
              country_id: (!isEmpty(response.data[0].head_office_country)) ? response.data[0].head_office_country.id : '',
              office_type: 'Head',
              pincode: null,
              member_id: id
            };
            setMemberHeadAddress(tmpHeadOffice);
          }

          if (!isEmpty(branch_office_address)) {
            setMemberBranchAddress(branch_office_address);
          }
          //  else {
          //   let tmpBranchOffice = [{
          //     address1: null,
          //     address2: null,
          //     city: null,
          //     country: (!isEmpty(response.data[0].branch_office_country)) ? response.data[0].branch_office_country : '',
          //     country_id: (!isEmpty(response.data[0].branch_office_country)) ? response.data[0].branch_office_country.id : '',
          //     office_type: 'Branch',
          //     pincode: null,
          //     member_id: id
          //   }];
          //   setMemberBranchAddress(tmpBranchOffice);
          // }

          // setMemberBranchAddress(branch_office_address);
          if (member_certificates) {
            setMemberCertificates(member_certificates);
          } else if (verified_member_certificates) {
            setMemberCertificates(verified_member_certificates);
          }
          // setMemberCertificates(member_certificates);
          setMemberContacts(member_contacts);
          setMemberServices(member_services);
          setMemberDetail(memberdetail);
          setMemberHsCode(hs_codes);
          setMemberTargetCountries(trade_countries);
          setCR(companyRepresentative);

          // console.log("memberdetail  >>> ", memberdetail);
          // console.log("hs_codes  >>> ", hs_codes);
          // console.log("trade_countries  >>> ", trade_countries);
          if (((!isEmpty(memberdetail) && isEmpty(memberdetail.about_company)) || isEmpty(memberdetail)) && isEmpty(hs_codes) && isEmpty(trade_countries)) {
            setShowOtherSection(false);
          } else {
            setShowOtherSection(true);
          }

          if (searchParams.get("lead") === null) {
            if (companyInformation.trade_mode_type !== "LOG_PTR") {
              tradeModeRef.current = "BUYER_SUPPLIER";
            } else {
              tradeModeRef.current = "LOG_PTR";
            }
          } else {
            let l = searchParams.get("lead");
            let l1 = atob(l);
            if (l1 === "WEB") {
              tradeModeRef.current = "REG_LEAD";
            }
          }
        }


      }
    }).catch((err) => {
      console.log("Error ============ ", err);
      // showErrorMessage("Something went wrong");
    });
    props.hideLoading();


  }

  useEffect(() => {
    if (initialRef.current === true) {
      initialRef.current = false;
      fetchCompanyProfile(id);
    }

  }, [id]);

  return (

    <div className='company-main'>

      {isSuperAdmin && tradeModeRef.current === "BUYER_SUPPLIER" && <SubHeader layer1="Buyers/Suppliers Management" layer2="Profile" layer3="View" pageName="Company View Profile" subTitle=""
        clickable={{
          layer1: MENU_ROUTES.MEMBERS,
          layer2: "",
          layer3: ""
        }}
      />}

      {isSuperAdmin && tradeModeRef.current === "LOG_PTR" && <SubHeader layer1="Logistics Management" layer2="Profile" layer3="View" pageName="Company View Profile" subTitle=""
        clickable={{
          layer1: MENU_ROUTES.LOGISTICS_MEMEBRS,
          layer2: "",
          layer3: ""
        }}
      />}

      {isSuperAdmin && tradeModeRef.current === "REG_LEAD" && <SubHeader layer1="Lead Management" layer2="Profile" layer3="View" pageName="Company View Profile" subTitle=""
        clickable={{
          layer1: MENU_ROUTES.MANAGE_LEADS,
          layer2: "",
          layer3: ""
        }}
      />}

      {
        isEndUser && (memberCompanyID == id) && <SubHeader layer1="Profile" layer2="View" layer3="" pageName="Company Update Profile" subTitle=""
          clickable={{
            layer1: "",
            layer2: "",
            layer3: ""
          }}
        />
      }

      {
        isEndUser && (memberCompanyID != id) && <SubHeader layer1="Buyers/Suppliers Management" layer2="Profile" layer3="View" pageName="Company View Profile" subTitle=""
          clickable={{
            layer1: MENU_ROUTES.MEMBERS,
            layer2: "",
            layer3: ""
          }}
        />
      }

      <div className='company-info-details'>

        <h3 className='company-details-text not-veryfied'>
          {/* <img src={companyDetails} alt="" className='company-info-icon mx-1' /> */}
          <div>
            <img src={CompanyInfoIcon} alt="" className='company-info-icon mx-1' />
            Company Information
          </div>

          {cInfo && (cInfo.status !== 1) && <div className='not-veryfied-logo'>
            <img src={NotVeryfiedots} alt="" />
            <img src={NotVeryfied} alt="" className='mx-2 not-veryfied-img' />
            <img src={NotVeryfiedots} alt="" />

          </div>}

        </h3>

        {/* ******company Info **** */}
        <div className='company-info-main bg-white'>
          <CompanyInfo data={cInfo} />
          <ServiceInfo data={memberServices} />
          <CertificatesInfo data={memberCertificates} />
        </div>
      </div>


      {/* ******company Images & videos **** */}


      {showMediaSection && <div className='company-pro-details '>

        <h3 className='company-details-text'>
          <img src={companyInoiconimg} alt="" className='company-info-icon-img-video' />
          Company Images/Videos
        </h3>

        <CompanyImageVideo
          data={mediaDetails}
          showLoading={props.showLoading}
          hideLoading={props.hideLoading}
        />

      </div>}


      {/* ******company other -details**** */}

      {showOtherSection && <div className=' company-pro-details'>
        <h3 className='company-details-text'>
          <img src={companyInoicon} alt="" className='company-info-icon mx-1' />
          Company Other Details
        </h3>
        <AboutCompany data={memberdetail} hs_codes={memberHsCode} target_countries={memberTargetCountries} images={""} />
      </div>
      }

      {/* *********contect info &&& location**** */}

      <div className='contect-company'>
        <div className='contect-info-compnay-main company-pro-details bg-white'>
          <h3>
            <img src={companyDetails} alt="" className='company-info-icon mx-1' />
            Contact Information
          </h3>

          {/* ****company-representive**** */}
          <div className='row justify-content-center mx-3'>

            <div className='contect-info-child row justify-content-between mt-3'>

              <ContactDetail title="Company Representative" data={cr} />
              {memberContacts && memberContacts.map((val, k) => {
                return (<ContactDetail title={`Contact Person ` + (k + 1)} data={val} key={`contact_${k}`} />)
              })}

            </div>

          </div>


        </div>

        {/* compnay-location */}

        <div className='contect-info-compnay-main company-pro-details bg-white'>
          <h3>
            <img src={companyLocation} alt="" className='company-info-icon mx-1' />
            Company Location
          </h3>

          <div className='row justify-content-center mx-3'>
            <div className='contect-info-child row justify-content-between mt-3 '>
              {/* head office===> */}

              <LocationDetail title="Head Office" data={memberHeadAddress} />

              {
                memberBranchAddress && memberBranchAddress.map((val, k) => {
                  return (<LocationDetail title={`Branch Office ` + (k + 1)} data={val} key={k} />)
                })
              }
            </div>
          </div>

        </div>

      </div>
      <ToastContainer />

    </div>

  )

}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    viewCompany: (member_id) => dispatch(viewProfileAction(member_id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);
