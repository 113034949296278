import React from "react";

import { Link, useNavigate } from "react-router-dom";

import FooterImg from "../src/imgs/footer-img.png";

function Footer() {
  const nav = useNavigate("");

  return (
    <footer className="footer-effect">
      <span className="decorative">
        <i className="sprite"></i>
      </span>
      <div className="footer">
        <div className="img-wrape">
          <img src={FooterImg} alt="" />
        </div>

        <div className="content-wrape">
          <div className="footer-about">
            <i className="sprite f-logo"></i>

            <p className="footer-text footer-logo-description mb-6">
              Since 2015, OREX TRADE WORLD INC has been simplifying global
              trade with smart tools, reliable data and trusted support
              empowering businesses to connect and grow effortlessly worldwide.
            </p>
          </div>

          <div className="right-section">
            <div className="inner-pad">
              <div className="section-pad">
                <h6 className="">Quick Links</h6>
                <ul className="">
                  <li>
                    <Link to="/" className="nav-link">
                      <i className="sprite"></i> Home
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className="nav-link">
                      <i className="sprite"></i> About Us
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/our-services">
                      <i className="sprite"></i> Our Services
                    </Link>
                  </li>
                  {/* <li>
                    <Nav.Link>
                      <i className="sprite"></i> Our Benefits
                    </Nav.Link>
                  </li> */}
                  <li>
                    <Link className="nav-link" to="/contact-us">
                      <i className="sprite"></i> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="section-pad">
                <h6 className="">Benefits</h6>
                <ul className="">
                  <li className="">
                    <Link
                      to="/benefits/global-advertising"
                      className="nav-link"
                    >
                      <i className="sprite"></i> Global Advertising
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/benefits/global-email-promotions"
                      className="nav-link"
                    >
                      <i className="sprite"></i> Global Email Promotions
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/benefits/lead-reports" className="nav-link">
                      <i className="sprite"></i> Lead Reports
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/benefits/seasonal-promotion"
                      className="nav-link"
                    >
                      <i className="sprite"></i> Seasonal Promotions
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/benefits/reference-verification"
                      className="nav-link"
                    >
                      <i className="sprite"></i> Reference Verification for
                      Buyers/Suppliers
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/benefits/guarantee-prospective-business"
                      className="nav-link"
                    >
                      <i className="sprite"></i> Guarantee to Get Prospective
                      Business
                    </Link>
                  </li>
                  <li className="">
                    <Link to="/benefits/logistics-trade" className="nav-link">
                      <i className="sprite"></i> Logistics Trades
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/benefits/trade-safety-guarantee"
                      className="nav-link"
                    >
                      <i className="sprite"></i> Trade Safety Guarantee
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="section-pad">
                {/* <h6 className="">Other</h6>
                <ul className="">
                  <li className="">
                    <Link className="nav-link">
                      <i className="sprite"></i> Terms of Service
                    </Link>
                  </li>
                  <li className="">
                    <Link className="nav-link">
                      <i className="sprite"></i> Privacy Policy
                    </Link>
                  </li>
                </ul> */}

                <h6 className="m-b-5">Offices</h6>
                <span className="flag usa m-b-15"></span>
                <span className="flag india m-b-15"></span>
                <span className="flag uae m-b-15"></span>

                <h6 className="">Connect Us</h6>
                <div className="social-media">
                  <a
                    href="https://www.linkedin.com/company/orextradeworld"
                    target="_blank"
                    className="linkedIn"
                  >
                    <i className="sprite"></i>
                  </a>

                  <a
                    href="https://www.facebook.com/people/OREX-TRADE-WORLD-INC/61571299940148/"
                    target="_blank"
                    className="fb"
                  >
                    <i className="sprite"></i>
                  </a>

                  <a
                    href="https://www.instagram.com/orextradeworld/"
                    target="_blank"
                    className="insta"
                  >
                    <i className="sprite"></i>
                  </a>

                  <a
                    href="https://x.com/Orextradeworld"
                    target="_blank"
                    className="twitter"
                  >
                    <i className="sprite"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span className="copy-right">
          Copyright © 2025 All Rights Reserved by OREX TRADE WORLD INC
        </span>

        <span className="signature">
          Design & Developed By{" "}
          <Link
            className="footer-design"
            to="https://infinglobal.com/"
            target="_blank"
          >
            {" "}
            InfinGlobal
          </Link>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
