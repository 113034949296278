import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import {
    isEmpty,
    find,
    includes,
    split,
    size,
    debounce,
} from "lodash";
import defaultValues from "../../constant/defaultValues";
import errorMessages from "../../constant/errorMessages";
import regExp from "../../constant/regExpressions";
import {
    showSuccessMessage,
    showErrorMessage,
} from "../../utils/CustomNotification";

import { getMemberList } from "../../actions/memberAction";
import { roleList } from "../../actions/rolesAction";

import { ToastContainer } from "react-toastify";

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from "../../components/InputField";
import MediumPopup from "../../components/MediumPopup";
import SmallPopup from "../../components/SmallPopup";
import SubHeader from "../../components/SubHeader";
import ButtonAdd from "../../components/ButtonAdd";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { createPlan, deletePlan, getPlan, getSubscriptionPlanList, updatePlan } from "../../actions/subscriptionPlanAction";

function SubscriptionPlans(props) {
    const { admin, USER_DEFAULTS, EMAIL_FIELD_MAX_LENGTH } = defaultValues;
    const statusList = USER_DEFAULTS.STATUS;

    const [planList, setPlanList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [searchTxt, setSearchTxt] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState(0);

    const [mediummodalShow, setMediumModalShow] = useState(false);
    const [smallmodalShow, setSmallModalShow] = useState(false);

    const [formdata, setFormData] = useState({
        name: "",
        price: 0,
        status: 1,
    });

    const [deletePlanId, setDeletePlanId] = useState();
    const [editedplanId, setEditedPlanId] = useState(0);
    const [statusOptions, setStatusOptions] = useState(statusList);
    const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
    const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
    const [current_page, setCurrentPage] = useState(1);

    const [errors, setErrors] = useState({});

    const initialRef = useRef(true);

    const columns = [
        {
            name: 'Sr No',
            selector: (row, idx) => {
                return (
                    <>{offset + (idx + 1)}</>
                )
            },
            resizable: true,
            maxWidth: "100px"
        },
        {
            name: "Plan Name",
            selector: (row) => <div className="tbl-text-wrap-row">{row.name}</div>, // white-space: pre;
            resizable: true,
            columnName: "name",
            sortable: true
            // maxWidth: "1000px",
        },
        /*{
            name: "Status",
            selector: (row) => {
                const rowStatus = statusOptions.find(option => option.value === row.status);
                return rowStatus.label;
            },
            resizable: true,
            maxWidth: "150px"
        }, */
        {
            name: "Actions",
            cell: (row) => (
                <>
                    {

                        <>
                            <button className="btn" onClick={() => handleEditClick(row.id)} data-toggle="tooltip" data-placement="top" title="Edit">
                                <i className="fa-regular fa-pen-to-square icon-pen"></i>
                            </button>
                            <button className="btn" onClick={() => handleDeleteClick(row.id)} data-toggle="tooltip" data-placement="top" title="Delete">
                                <i className="fa-solid fa-trash-can icon-trash" aria-hidden="true"></i>
                            </button> </>

                    }
                </>
            ),
            resizable: true,
            ignoreRowClick: true,
            allowoverflow: true,
            button: true,
            maxWidth: "250px"
            // minWidth: "200px"
        },

    ];

    const handleSort = async (column, sortDirection) => {
        setColumnName(column.columnName);
        setSortDirection(sortDirection);
        getSubscriptionsPlansData(
            admin.OFFSET,
            admin.LIMIT,
            current_page,
            column.columnName,
            sortDirection,
            searchTxt
        );
    };

    const handleAddUserClick = () => {
        setFormData({
            name: "",
            price: 0,
            status: 1
        });
        setErrors({});
        setEditedPlanId(0);
        setMediumModalShow(true);
    };

    const handleEditClick = async (id) => {
        setErrors({});
        await props.showLoading();
        await props
            .getPlan(id)
            .then(async (response) => {
                setFormData({
                    name: response.data[0].name,
                    price: 0
                });
                setMediumModalShow(true);
                setEditedPlanId(id);

            })
            .catch((err) => {
                console.log("Error ===== ", err);
                showErrorMessage("Something Went Wrong!!");
            });
        await props.hideLoading();
    };

    const handleDeleteClick = (id) => {
        setSmallModalShow(true);
        setDeletePlanId(id);
    };

    const handleConfirmDeleteClick = () => {
        props
            .deletePlan(deletePlanId)
            .then((response) => {
                if (response.status === true) {
                    showSuccessMessage("Plan Deleted Successfully");
                    setOffset(admin.OFFSET);
                    setLimit(admin.LIMIT);
                    setColumnName(admin.COLUMN_NAME);
                    setSortDirection(admin.SORT_DIRECTION);
                    setSearchTxt("");
                    getSubscriptionsPlansData(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
                } else {
                    showErrorMessage(response.message);
                }
                setSmallModalShow(false);
            })
            .catch((err) => {
                console.log("Error == ", err);
                showErrorMessage("Something Went Wrong!!");
            });
    };

    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleFocusOut = (e) => {
        let errObj = { ...errors };
        if (e.target.name === "name") {
            if (isEmpty(e.target.value)) {
                errObj.name = errorMessages.FIELD_REQUIRED;
            } else if (size(e.target.value) > 255) {
                errObj.name = errorMessages.MAX_CHARACTERS_REACHED;
            } else if (!isEmpty(e.target.value)) {
                const nameregExp = regExp.name;
                const check = nameregExp.test(e.target.value);
                if (check === false) {
                    errObj.name = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    errObj.name = "";
                }
            }
        }
        if (e.target.name === "email") {
            if (isEmpty(e.target.value)) {
                errObj.email = errorMessages.FIELD_REQUIRED;
            } else if (size(e.target.value) > EMAIL_FIELD_MAX_LENGTH) {
                errObj.email = errorMessages.MAX_CHARACTERS_REACHED;
            } else if (!isEmpty(e.target.value)) {
                let emailval = e.target.value;
                let emailDomain = split(emailval, "@");
                const emailregExp = regExp.email;
                const check = emailregExp.test(e.target.value);
                if (check === false) {
                    errObj.email = errorMessages.IN_VALID_FIELD_FORMAT;
                } else if (includes(defaultValues.IGNORE_EMAILS, emailDomain[1])) {
                    errObj.email = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    errObj.email = "";
                }
            }
        }
        if (e.target.name === "member_id") {
            if (isEmpty(e.target.value)) {
                errObj.member_id = errorMessages.FIELD_REQUIRED;
            }
        }
        if (e.target.name === "role_id") {
            if (isEmpty(e.target.value)) {
                errObj.role_id = errorMessages.FIELD_REQUIRED;
            }
        }
        setErrors(errObj);

    };

    const validForm = () => {

        let nameflag = 0;

        let errObj = { ...errors };

        if (isEmpty(formdata.name)) {
            nameflag = 1;
            errObj.name = errorMessages.FIELD_REQUIRED;
        } else if (!isEmpty(formdata.name)) {
            if (size(formdata.name) > 255) {
                nameflag = 1;
                errObj.name = errorMessages.MAX_CHARACTERS_REACHED;
            } else if (!regExp.name.test(formdata.name)) {
                nameflag = 1;
                errObj.name = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                nameflag = 0;
                errObj.name = "";
            }
        }

        setErrors(errObj);

        if (nameflag === 1) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("Form is submitted");
        if (validForm()) {
            props.showLoading();
            if (editedplanId > 0) {
                // console.log("Edit plan clicked.");
                await props
                    .updatePlan(editedplanId, formdata)
                    .then((response) => {
                        // console.log("Response123456 ========== ", response);
                        if (response.status === true) {
                            setMediumModalShow(false);
                            setOffset(admin.OFFSET);
                            setLimit(admin.LIMIT);
                            setColumnName(admin.COLUMN_NAME);
                            setSortDirection(admin.SORT_DIRECTION);
                            setSearchTxt("");
                            getSubscriptionsPlansData(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
                            showSuccessMessage(response.message);
                        } else if (response.status === false) {
                            let errObj = {};
                            if (response.error) {
                                if (response.error.name) {
                                    errObj.name = response.error.name[0];
                                }
                            }
                            setErrors(errObj);
                        }
                    })
                    .catch((err) => {
                        console.log("Error ======= ", err);
                        showErrorMessage("something went wrong!");
                    });
            } else {
                // console.log("Add clicked...");
                await props
                    .savePlan(formdata)
                    .then((response) => {
                        // console.log("Response ==== ", response);
                        if (response.status === true) {
                            setMediumModalShow(false);
                            setOffset(admin.OFFSET);
                            setLimit(admin.LIMIT);
                            setColumnName(admin.COLUMN_NAME);
                            setSortDirection(admin.SORT_DIRECTION);
                            setSearchTxt("");
                            getSubscriptionsPlansData(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
                            showSuccessMessage(response.message);
                        } else if (response.status === false) {
                            let errObj = {};
                            // console.log("Error =========== ", response.error);
                            if (response.error) {

                                if (response.error.name) {
                                    errObj.name = response.error.name[0];
                                }

                            }

                            setErrors(errObj);
                        }
                    })
                    .catch((err) => {
                        console.log("Error ====== ", err);
                        showErrorMessage("Something Went Wrong!!");
                    });
            }
            props.hideLoading();
        }
    };

    const onSearchTextBoxChanged = debounce((e) => {
        setSearchTxt(e.target.value);
        let searchVal = e.target.value;
        if (searchVal.length >= 3 || searchVal.length <= 0) {
            setOffset(admin.OFFSET);
            setLimit(admin.LIMIT);
            setColumnName(admin.COLUMN_NAME);
            setSortDirection(admin.SORT_DIRECTION);
            getSubscriptionsPlansData(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
        }

    });



    const getSubscriptionsPlansData = async (offset, limit, current_page, columnName, sortDirection, searchTxt) => {
        await props.showLoading();
        await props
            .getSubscriptionPlans(offset, limit, columnName, sortDirection, searchTxt)
            .then((response) => {
                setPlanList([]);
                setPlanList(response.data);
                settotalRows(response.metadata.total);
            })
            .catch((err) => {
                console.log("Error ==== ", err);
                showErrorMessage("Something Went Wrong!!");
            });
        await props.hideLoading();
    };

    useEffect(() => {
        if (initialRef.current === true) {
            initialRef.current = false;
            getSubscriptionsPlansData(offset, limit, current_page, columnName, sortDirection, searchTxt);
        }

    }, []);

    return (
        <>
            <div className='data-table-main'>

                <div className="AcSimpleTable">

                    <SubHeader layer1="Subscription Plans" layer2="List" layer3="" pageName="Subscription Plans" subTitle="" clickable={{
                        layer1: "",
                        layer2: "",
                        layer3: ""
                    }} />

                    <div className="AcSimpleTable">

                        {/** Add/Edit Subscription plan popup */}
                        <MediumPopup
                            title={editedplanId > 0 ? "Edit Plan" : "Create Plan"}
                            mediummodalShow={mediummodalShow}
                            setMediumModalShow={setMediumModalShow}
                            onSubmit={handleSubmit}
                            okButtonLabel={editedplanId > 0 ? "Update" : "Create"}
                            closeButtonLabel="Cancel"
                        >
                            <form onSubmit={handleSubmit}>
                                {/* <div className="row justify-content-cetner align-items-center"> */}
                                <div className='row my-3'>
                                    <div className='col-xl-6 mt-1'>
                                        <label className='popup-input-lable field-required'>Name</label>
                                        <InputField
                                            className=""
                                            placeholder={"Enter plan name"}
                                            name="name"
                                            onChange={handleChange}
                                            value={formdata.name}
                                            onBlur={handleFocusOut}
                                            maxLength={255 + 1}
                                            autoComplete="off"
                                        ></InputField>
                                        {errors.name && (
                                            <p
                                                className="invalid-feedback"
                                                style={{ display: "block" }}
                                            >
                                                {errors.name}
                                            </p>
                                        )}
                                    </div>
                                </div>

                            </form>
                        </MediumPopup>

                        {/** Delete User popup */}
                        <SmallPopup
                            title="Delete Plan"
                            smallmodalShow={smallmodalShow}
                            setSmallModalShow={setSmallModalShow}
                            onSubmit={handleConfirmDeleteClick}
                            closeButtonLabel="Cancel"
                            okButtonLabel="Delete"
                        >
                            <div className='row my-3'>
                                <div className="col-md-12 p-0">Are you sure you want to delete plan?</div>
                            </div>

                        </SmallPopup>

                        <div className="card1">

                            <div className="table-header-name">
                                <h5 className="table-text py-3">List</h5>
                                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                                    <label className="mx-3">Search</label>
                                    <InputField
                                        type="text"
                                        id="filter-text-box"
                                        placeholder="Search..."
                                        className="w-25"
                                        // onChange={onSearchTextBoxChanged}
                                        onKeyUp={onSearchTextBoxChanged}
                                        // value={searchTxt}
                                        autoComplete="off"
                                    ></InputField>

                                    <ButtonAdd
                                        type="button"
                                        className="btn-add me-0"
                                        onClick={handleAddUserClick}
                                    >
                                        {" "}
                                        <i className="fa-solid fa-circle-plus"></i> Add New Plan
                                    </ButtonAdd>

                                </div>
                            </div>

                            <div className="table-wrapper">
                                <div className="table-container">
                                    {planList && (
                                        <AcSimpleTable
                                            data={planList}
                                            title=""
                                            pagination={true}
                                            paginationPageSize={limit}
                                            columns={columns}
                                            progressPending={loading}
                                            paginationTotalRows={totalRows}
                                            fixedColumnsStart={admin.FIXED_COLUMNS_START}
                                            getData={getSubscriptionsPlansData}
                                            offset={offset}
                                            limit={limit}
                                            columnName={columnName}
                                            sortDirection={sortDirection}
                                            setLimit={setLimit}
                                            setOffset={setOffset}
                                            setColumnName={setColumnName}
                                            setSortDirection={setSortDirection}
                                            setLoading={setLoading}
                                            paginationPerPage={limit}
                                            paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                                            striped
                                            searchTxt={searchTxt}
                                            sortServer
                                            onSort={handleSort}
                                            defaultSortAsc={false}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>


                    </div>

                    <ToastContainer />
                </div>

            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user_list: state.users.userlist,
        member_list: state.member.memberlist,
        role_list: state.roles.rolelist
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSubscriptionPlans: (offset, limit, columnName, sortDirection, searchTxt) =>
            dispatch(getSubscriptionPlanList(offset, limit, columnName, sortDirection, searchTxt)),
        getMembers: (offset, limit, searchTxt) =>
            dispatch(getMemberList(offset, limit, searchTxt)),
        savePlan: (data) => dispatch(createPlan(data)),
        deletePlan: (id) => dispatch(deletePlan(id)),
        getPlan: (id) => dispatch(getPlan(id)),
        updatePlan: (id, data) => dispatch(updatePlan(id, data)),
        getRoles: () => dispatch(roleList()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);
