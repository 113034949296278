const defaultValues = {
    admin: {
        LIMIT: 10,
        OFFSET: 0,
        PAGINATE_RANGE: [1, 2, 5, 10, 25, 50, 100],
        FIXED_COLUMNS_START: 0,
        COLUMN_NAME: "created_at",
        SORT_DIRECTION: "desc"
    },
    enduser: {
        LIMIT: 50,
        OFFSET: 0,
        PAGINATE_RANGE: [50, 100, 150, 200],
        FIXED_COLUMNS_START: 2
    },
    member: {
        status: {
            PENDING: 0,
            ACTIVE: 1,
            VERFIED: 2,
            PAYMENT_DUE: 3,
            DELETED: 4
        }
    },
    USER_DEFAULTS: {
        STATUS: [
            {
                label: "Pending",
                value: 0
            }, {
                label: "Active",
                value: 1
            }, {
                label: "De-Activated",
                value: 2
            }, {
                label: "Expired",
                value: 3
            }
        ]
    },
    IGNORE_EMAILS: ['yopmail.com', 'mailinator.com', '10minutemail.com', 'guerrillamail.com', 'trashmail.com', 'temp-mail.org', 'dispostable.com', 'fakeinbox.com', 'sharklasers.com', 'getairmail.com', 'maildrop.cc', 'spambog.com', 'mytemp.email', 'throwawaymail.com', 'tempmailaddress.com', 'mailcatch.com', 'moakt.com', 'temp-mail.io', 'tutanota.com'],
    COMPANY_DEFAULTS: {
        STATUS: [
            {
                label: "Pending",
                value: 0
            },
            {
                label: "Active",
                value: 1
            },
            {
                label: "In Progress",
                value: 2
            },
            {
                label: "Sent for Approval",
                value: 3
            },
            {
                label: "Rejected",
                value: 4
            },
            {
                label: "Suspended",
                value: 10
            }
        ]
    },
    NAME_FIELD_MAX_LENGTH: 50,
    EMAIL_FIELD_MAX_LENGTH: 50,
    PASSWORD_FIELD_MAX_LENGTH: 40,
    CERTIFICATE_NAME_FIELD_MAX_LENGTH: 255,
    CERTIFICATE_SLUG_FIELD_MAX_LENGTH: 255,
    HS_CODE_MAX_LENGTH: 12,
    IMAGE_PATH: {
        BASE_URL: process.env.REACT_APP_IMAGE_BASE_URL
    },
    MAX_PROFILE_UPLOAD_SIZE: 2000000,
    SOCIAL_MEDIA_OPTIONS: [
        {
            value: 1,
            label: 'Facebook',
            icon: <i className="fa-brands fa-facebook text-primary"></i>

        },
        {
            value: 2,
            label: 'LinkedIn',
            icon: <i className="fa-brands fa-linkedin text-info"></i>

        },
        {
            value: 3,
            label: 'Twitter',
            icon: <i className="fa-brands fa-x-twitter text-dark"></i>

        },
        {
            value: 4,
            label: 'Instagram',
            icon: <i className="fa-brands fa-instagram text-danger"></i>

        },
        {
            value: 5,
            label: 'YouTube',
            icon: <i className="fa-brands fa-youtube text-danger"></i>

        },
        {
            value: 6,
            label: 'Others',
            icon: <i className="fa-solid fa-network-wired"></i>

        }
    ],
    TRADE_MODE: [{
        label: 'Import',
        value: 'Import'
    }, {
        label: 'Export',
        value: 'Export'
    },
    {
        label: 'Logistic Partner',
        value: 'Logistic Partner'
    },
    {
        label: 'Trader',
        value: 'Trader'
    },
    {
        label: 'Manufacturer',
        value: 'Manufacturer'
    }],
    TERMS: [{
        label: 'Ex-Works or Ex-Warehouse',
        value: 'EXW'
    },
    {
        label: 'Free to Carrier',
        value: 'FCA'
    },
    {
        label: 'Free Alongside Ship',
        value: 'FAS'
    },
    {
        label: 'Free On Board',
        value: 'FOB'
    },
    {
        label: 'Cost and Freight',
        value: 'CFR'
    },
    {
        label: 'Cost, Insurance and Freight',
        value: 'CIF'
    },
    {
        label: 'Carriage Paid To',
        value: 'CPT'
    },
    {
        label: 'Carriage And Insurance Paid To',
        value: 'CIP'
    },
    {
        label: 'Delivered At Place',
        value: 'DAP'
    },
    {
        label: 'Delivered At Place Unloaded (replaces Incoterm® 2010 DAT)',
        value: 'DPU'
    },
    {
        label: 'Delivered Duty Paid',
        value: 'DDP'
    }],
    TRANSPORT_MODE: [{
        label: 'Maritime',
        value: 'Maritime'
    }, {
        label: 'Air',
        value: 'Air'
    }, {
        label: 'Land',
        value: 'Land'
    }, {
        label: 'Rail',
        value: 'Rail'
    }, {
        label: 'Others',
        value: 'Others'
    }],
    FLAG_OPTIONS: [
        {
            value: 'en',
            label: 'ENGLISH',
            icon: <i className="flag-icon flag-icon-us"></i>

        },
        {
            value: 'fr',
            label: 'FRENCH',
            icon: <i className="flag-icon flag-icon-fr"></i>

        },
        {
            value: 'de',
            label: 'GERMAN',
            icon: <i className="flag-icon flag-icon-de"></i>

        },
        {
            value: 'es',
            label: 'SPANISH',
            icon: <i className="flag-icon flag-icon-es"></i>

        },
        {
            value: 'ja',
            label: 'JAPANESE',
            icon: <i className="flag-icon flag-icon-jp"></i>

        },
        {
            value: 'ru',
            label: 'RUSSIAN',
            icon: <i className="flag-icon flag-icon-ru"></i>

        },
        {
            value: 'zh-CN',
            label: 'CHINESE',
            icon: <i className="flag-icon flag-icon-cn"></i>

        },
        
    ],
}

export default defaultValues;
