import { connect } from "react-redux";
import defaultValues from "../../constant/defaultValues";
import { useEffect, useRef, useState } from "react";
import AcSimpleTable from "../../components/AcSimpleTable";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/CustomNotification";
import { createIndustry, deleteIndustry, getIndustries, updateIndustry } from "../../actions/dictionaryAction";
import { ToastContainer } from "react-toastify";
import ButtonAdd from "../../components/ButtonAdd";
import InputField from "../../components/InputField";
import SubHeader from "../../components/SubHeader";
import SmallPopup from "../../components/SmallPopup";
import MediumPopup from "../../components/MediumPopup";
import { isEmpty } from "lodash";
import errorMessages from "../../constant/errorMessages";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import DropDown from "../../components/DropDown";
import regExp from "../../constant/regExpressions";

function ManageIndustries(props) {
  const { admin } = defaultValues;
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [editIndustryId, setEditIndustryId] = useState();
  const [errors, setErrors] = useState({});
  const [industryName, setIndustryName] = useState("");
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [deleteIndustryId, setDeleteIndustryId] = useState();
  const [tradeMode, setTradeMode] = useState("");
  const initialRef = useRef(true);
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
  const [current_page, setCurrentPage] = useState(1);

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%",
    },
    {
      name: "Industry Name",
      selector: (row) => (
        <div className="tbl-text-wrap-row">
          {row.industy_name ? row.industy_name : "-"}
        </div>
      ),
      columnName: "industy_name",
      resizable: true,
      width: "20%",
      sortable: true
    },
    {
      name: "Trade Mode",
      selector: (row) => (
        <div className="text-wrap">{row.trade_mode ? row.trade_mode : "-"}</div>
      ),
      columnName: "trade_mode",
      resizable: true,
      width: "20%",
      sortable: true
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "20%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "10%",
      // minWidth: "200px"
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getIndustryList(
      admin.OFFSET,
      admin.LIMIT,
      current_page,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const onSearchTextBoxChanged = (e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      setLimit(admin.LIMIT);
      getIndustryList(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }
  };

  const getIndustryList = async (offset, limit, current_page, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getIndustries(offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        setIndustryList([]);
        setIndustryList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const handleEditClick = async (row) => {
    // await props.showLoading();
    setErrors({});
    setIndustryName(row.industy_name);
    const selectedTradeModeOption = tradeModeOption.find(
      (option) => option.value == row.trade_mode
    );
    setTradeMode(selectedTradeModeOption);
    setEditIndustryId(row.id);
    setMediumModalShow(true);

    // await props.hideLoading();
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "industry_name") {
      if (isEmpty(e.target.value)) {
        errObj.industryName = errorMessages.FIELD_REQUIRED;
      } else {
        // errObj.serviceName = "";
        const industryRegExp = regExp.designation;
        const check = industryRegExp.test(e.target.value);
        if (check === false) {
          errObj.industryName = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          errObj.industryName = '';
        }
      }
      // else {
      //   errObj.industryName = "";
      // }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;

    if (isEmpty(industryName)) {
      errFlag = 1;
      er.industryName = errorMessages.FIELD_REQUIRED;
    } else {
      const industryRegExp = regExp.designation;
      const check = industryRegExp.test(industryName);
      if (check === false) {
        er.industryName = errorMessages.IN_VALID_FIELD_FORMAT;
      }
      else {
        er.industryName = '';
      }
    }

    if (isEmpty(tradeMode)) {
      errFlag = 1;
      er.tradeMode = errorMessages.FIELD_REQUIRED;
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setMediumModalShow(false);

    if (validForm()) {
      props.showLoading();
      if (editIndustryId > 0) {
        const reqObj = {
          industy_name: industryName,
          trade_mode: tradeMode.value
        };
        await props
          .updateIndustry(editIndustryId, reqObj)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setLimit(admin.LIMIT);
              setSearchTxt("");
              getIndustryList(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error) {
                if (response.error == "The data has already been taken.") {
                  errObj.industryName = response.error;
                }
                if (response.error.industy_name) {
                  errObj.industryName = response.error.industy_name[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          industy_name: industryName,
          trade_mode: tradeMode.value
        };
        await props
          .createIndustry(reqData)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setLimit(admin.LIMIT);
              setSearchTxt("");
              getIndustryList(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              // console.log("Error =========== ", response);
              if (response.error) {
                if (response.error == "The data has already been taken.") {
                  errObj.industryName = response.error;
                }
                if (response.error.industy_name) {
                  errObj.industryName = response.error.industy_name[0];
                }
                // if (response.error.service_name) {
                //   errObj.serviceName = response.error.service_name[0];
                // }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleChange = (e) => {
    setIndustryName(e.target.value);
  };

  const handleAddIndustryClick = () => {
    setIndustryName();
    setErrors({});
    setEditIndustryId(0);
    setTradeMode('');
    setMediumModalShow(true);
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteIndustryId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteIndustry(deleteIndustryId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("Industry Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getIndustryList(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        } else if (response.status === false) {
          if (
            response.error === "The industry is already used by some company"
          ) {
            showErrorMessage(response.error);
          }
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const tradeModeOption = [
    { label: "Import", value: "Import" },
    { label: "Export", value: "Export" },
    { label: "Logistic Partner", value: "Logistic Partner" },
    { label: "Trader", value: "Trader" },
    { label: "Manufacturer", value: "Manufacturer" },
  ];

  const handleTradeMode = (val) => {
    setTradeMode(val);
  };

  useEffect(() => {
    if (initialRef.current === true) {
      initialRef.current = false;
      getIndustryList(offset, limit, current_page, columnName, sortDirection, searchTxt);
    }

  }, []);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editIndustryId > 0 ? "Edit Industry" : "Create Industry"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editIndustryId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}
              <div className="row my-3">
                <div className="col-xl-6 mt-1">
                  <label className="popup-input-lable field-required">
                    Industry Name
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter Industry Name"}
                    name="industry_name"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={industryName}
                    autoComplete="off"
                  ></InputField>

                  {errors.industryName && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.industryName}
                    </p>
                  )}
                </div>
                <div className="col-xl-6 mt-1">
                  <label className="profile-text mb-1">
                    Trade Mode
                    <span className="text-danger"> *</span>
                  </label>

                  <DropDown
                    placeholder={"Select Trade Mode"}
                    options={tradeModeOption}
                    onChange={handleTradeMode}
                    value={tradeMode}
                    name="trade_mode"
                  ></DropDown>

                  {errors.tradeMode && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.tradeMode}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </MediumPopup>
          <SmallPopup
            title="Delete Industry"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className="row my-3">
              <div className="col-md-12 p-0">
                Are you sure you want to delete Industry?
              </div>
            </div>
          </SmallPopup>

          <SubHeader
            layer1="Dictionary Management"
            layer2="Industries"
            layer3="Verified"
            pageName="Industries"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onChange={onSearchTextBoxChanged}
                    value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  <ButtonAdd
                    type="button"
                    className="btn-add me-0"
                    onClick={handleAddIndustryClick}
                  >
                    {" "}
                    <i className="fa-solid fa-circle-plus"></i> Add New Industry
                  </ButtonAdd>
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {industryList && (
                    <AcSimpleTable
                      data={industryList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getIndustryList}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIndustries: (offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(getIndustries(offset, limit, columnName, sortDirection, searchTxt)),
    updateIndustry: (id, data) => dispatch(updateIndustry(id, data)),
    createIndustry: (data) => dispatch(createIndustry(data)),
    deleteIndustry: (id) => dispatch(deleteIndustry(id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageIndustries);
