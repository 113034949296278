import React, { useEffect, useState } from "react";

import axios from "axios";
import API_URL from "../config/apiConfig";

import Header from "./Header";
import DropDown from "../components/DropDown";
import Button from "../components/Button";
import RadioButtonField from "../components/RadioButtonField";
import InputField from "../components/InputField";
import { Link, useNavigate } from "react-router-dom";
import MediumPopup from "../components/MediumPopup";
import RegistrationPopup from "../components/RegistrationPopup";
import NoDataFound from "../components/common/NoDataFound";
import Loaders from "./Loaders";
import errorMessages from "../constant/errorMessages";
import no_data_found from "../images/no-data-found.png";
import findPartnerImag from "../images/search-buyer.jpg"
import { capitalize, map } from "lodash";

function BuyerSuplierData() {
  let navigate = useNavigate(null);
  const [countryList, setCountryList] = useState(null);
  const [Hscode, setHscode] = useState(null);

  const [country, setcountry] = useState(null);
  const [countryVal, setCountryVal] = useState(null);
  const [Hscodefind, setHscodefind] = useState(null);
  const [hscodeVal, setHsCodeVal] = useState(null);

  const [getdata, setgetdata] = useState(null);

  const [isRadioCheckedBuyer, setIsRadioCheckedBuyer] = useState(true);
  const [isRadioCheckedSeller, setIsRadioCheckedSeller] = useState(false);
  const [smallmodalShow, setSmallModalShow] = useState(false);

  const [types, setTypes] = useState("Buyer");
  const [product, setProduct] = useState('');

  const [loading, setLoading] = useState(false);
  const [verrors, setVErrors] = useState({});

  const [img, setImag] = useState(findPartnerImag);

  const fetchCountries = async () => {
    await axios
      .get(API_URL + "/most-used-import-export-country/" + types)
      .then((response) => {
        //console.log("API_REsponse=========>", response);
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((country) => ({
              value: country.country,
              label: map(country.country.split(" "), capitalize).join(" ")
            }));
            setCountryList(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };

  const fetchHscode = async () => {
    let searchType = types;
    await axios
      .get(API_URL + "/most-used-hs-codes/" + searchType)
      .then((response) => {
        // console.log("API_REsponse=========>", response);
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((hscode) => ({
              value: hscode.hs_code,
              label: hscode.hs_code,
            }));
            setHscode(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error============", err);
      });
  };

  const hendleChanegeCountry = async (e) => {
    setcountry(e);
    setCountryVal(e.value);
    setHscode(null);
    setHscodefind(null);
    setHsCodeVal(null);
    let country = e.value;
    let searchType = types;


    await axios
      .get(API_URL + "/most-used-hs-codes/" + searchType + '/' + country)
      .then((response) => {
        // console.log("HS Code API_REsponse Country=========>", response);
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((hscode) => ({
              value: hscode.hs_code,
              label: hscode.hs_code,
            }));
            setHscode(options);
          }

        }
      }).catch((err) => {
        console.log("Error============", err);
      });

    if (country !== null || country !== '') {
      let er = { ...verrors };
      let flag = 0;
      er.country = '';
      er.hsCode = '';
      er.product = '';
      setVErrors(er);
      return true;
    }
  };

  const hendleChanegeHscode = (e) => {
    setHscodefind(e);
    setHsCodeVal(e.value);
    let hsCode = e.value;
    if (hsCode !== null || hsCode !== '') {
      let er = { ...verrors };
      let flag = 0;
      er.country = '';
      er.hsCode = '';
      er.product = '';
      setVErrors(er);
      return true;
    }
  };

  const handleChangeTxt = (e) => {
    setProduct(e.target.value);
    let prod = e.target.value;
    if (prod !== null || prod !== '') {
      let er = { ...verrors };
      let flag = 0;
      er.country = '';
      er.hsCode = '';
      er.product = '';
      setVErrors(er);
      return true;
    }

  }



  const handleButtonClickBuyer = () => {
    setIsRadioCheckedBuyer(true);
    setIsRadioCheckedSeller(false);
    setTypes("Buyer");
    setcountry(null);
    setCountryVal(null);
    setHscodefind(null);
    setHsCodeVal(null);
    setgetdata(null);
    fetchCountries();
    fetchHscode();
    setProduct('');
    setVErrors({});

  };

  const handleButtonClickSeller = () => {
    setIsRadioCheckedSeller(true);
    setIsRadioCheckedBuyer(false);
    setTypes("Supplier");
    setcountry(null);
    setCountryVal(null);
    setHscodefind(null);
    setHsCodeVal(null);
    setgetdata(null);
    fetchCountries();
    fetchHscode();
    setProduct('');
    setVErrors({});
  };

  const handleConfirmLoginClick = () => {
    navigate("/registration");
  };

  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("token") !== null) {
        navigate("/members");
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (types) {
      fetchCountries();
      fetchHscode();
    }
  }, [types]);

  const validation = () => {
    let er = { ...verrors };
    let flag = 0;

    if (countryVal === null && hscodeVal === null && product === '') {
      flag = 1;
      er.country = errorMessages.FIELD_REQUIRED;
      er.hsCode = errorMessages.FIELD_REQUIRED;
      er.product = errorMessages.FIELD_REQUIRED;
    } else {
      if (countryVal !== null && countryVal !== '') {
        flag = 0;
        er.country = '';
        er.hsCode = '';
        er.product = '';
      }
      if (hscodeVal !== null && hscodeVal !== '') {
        flag = 0;
        er.country = '';
        er.hsCode = '';
        er.product = '';
      }
      if (product !== null && product !== '') {
        flag = 0;
        er.country = '';
        er.hsCode = '';
        er.product = '';
      }
    }

    setVErrors(er);
    if (flag === 1) {
      return false;
    } else {
      return true;
    }
  };


  const HandleBuyerSupplierData = async (e) => {
    e.preventDefault();

    if (validation()) {

      setTimeout(() => {
        if (localStorage.getItem('token') !== null) {
          navigate('/members');
        }
      }, 2000);
      setLoading(true);
      await axios
        .post(
          API_URL + "/find-import-export",
          { country: countryVal, hsCode: hscodeVal, findWhom: types, product: product },
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status === true) {
            console.log("RESULT====>", response.data.data);
            if (response.data.data.length === 0) {
              setImag(no_data_found);
            }
            setgetdata(response.data.data);
          } else {
            setVErrors([]);
            const errors = {};
            if (response.data.error.country) {
              errors.country = response.data.error.country;
            }
            if (response.data.error.hsCode) {
              errors.hsCode = response.data.error.hsCode;
            }
            if (response.data.error.product) {
              errors.product = response.data.error.product;
            }
            setVErrors(errors);
            return Object.keys(errors).length > 0;
          }
        })

        .catch((err) => {
          console.log("Error============>", err);
          setLoading(false);
        });
    }
  };

  const handleReset = () => {
    setgetdata(null);
    setProduct('');
    setcountry(null);
    setCountryVal(null);
    setHscodefind(null);
    setHsCodeVal(null);
    setTypes("Buyer");
    setVErrors([]);
    setImag(findPartnerImag);
  };

  const styleError = {
    display: "block",
  };

  return (
    <div className="find-buyer-supplier">
      {/* <Header /> */}
      {/* <AboutBanner /> */}

      <section className="find-buyer-supplier-wrape">
        <section className="inner-banner-wrape about-us banner-wrape">
          <div className="inner">FIND BUYERS & SUPPLIERS</div>
          <span class="sprite decoration"></span>
        </section>
        <form onSubmit={HandleBuyerSupplierData}>
          <div className="container-fluid">
            <div className="section-head">
              <div className="head mb-0">
                <p>Orex Trade To</p>
                <h2>
                  <span>FIND </span>BUYERS & SUPPLIERS
                </h2>
              </div>
              <p className="text">
                Finding buyers for your products is essential for a successful
                trading business. Here's how Orex Trade can be used <br />{" "}
                effectively to identify potential buyers
              </p>

              <div className="buyer-supplier-btn">
                <div className="d-flex justify-content-center align-items-center">
                  <RadioButtonField
                    value="Buyer"
                    name="findWhom"
                    checked={isRadioCheckedBuyer}
                    className={`${isRadioCheckedBuyer ? "d-block" : "d-none"
                      } mx-2 form-check-input d-none`}
                  />
                  <Link
                    className={`btn primary-btn buyer ${isRadioCheckedBuyer ? "" : "deactive-buyer"
                      } `}
                    onClick={handleButtonClickBuyer}
                  >
                    FIND BUYERS
                  </Link>
                  <b className="mx-1"> OR </b>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <RadioButtonField
                    value="Supplier"
                    name="findWhom"
                    checked={isRadioCheckedSeller}
                    className={`${isRadioCheckedSeller ? "d-block" : "d-none"
                      } mx-2 form-check-input d-none`}
                  />
                  <Link
                    className={`btn primary-btn ${isRadioCheckedSeller ? "active-supplier" : ""
                      }supplier`}
                    onClick={handleButtonClickSeller}
                  >
                    FIND SUPPLIERS
                  </Link>
                </div>
              </div>
            </div>

            <div className="row justify-content-center align-items-end ">
              <div className="col-xl-10 col-11 buyer-suplier-search-section search-section p-0">

                <div className="buyer-suplier-section-child">

                  <label
                    className={`form-label ${product || hscodeVal ? "" : "required"
                      }`}
                  >
                    Country Name
                  </label>

                  <div className="d-flex align-items-center">
                    <div className="w-100">
                      <DropDown
                        placeholder="Select country name"
                        options={countryList}
                        onChange={hendleChanegeCountry}
                        // value={country?.value}
                        value={country}
                        noOptionsMessage={() => "No Country Found"}
                      />
                    </div>


                  </div>

                  {verrors.country && (
                    <div className="invalid-feedback" style={styleError}>
                      {verrors.country}
                    </div>
                  )}

                </div>

                <div className="buyer-suplier-section-child">
                  <label
                    className={`form-label ${countryVal || product ? "" : "required"
                      }`}
                  >
                    HS Code
                  </label>
                  <div className="d-flex align-items-center">
                    <div className="w-100">
                      <DropDown
                        placeholder="Select HS code"
                        options={Hscode}
                        onChange={hendleChanegeHscode}
                        value={Hscodefind}
                        noOptionsMessage={() => "No HS code Found"}
                      />
                    </div>

                  </div>


                  {verrors.hsCode && (
                    <div className="invalid-feedback" style={styleError}>
                      {verrors.hsCode}
                    </div>
                  )}
                </div>

                <div className="buyer-suplier-section-child">
                  <label
                    className={`form-label ${countryVal || hscodeVal ? "" : "required"
                      }`}
                  >
                    Product Name
                  </label>

                  <InputField
                    name="product"
                    onChange={handleChangeTxt}
                    value={product}
                    placeholder="Enter product name"
                  />

                  {verrors.product && (
                    <div className="invalid-feedback" style={styleError}>
                      {verrors.product}
                    </div>
                  )}
                </div>

                <div className="buyer-suplier-section-child search-btn">
                  <button
                    type="submit"
                    className="btn primary-btn mx-2 buyser-supplier-search"
                  >
                    Search
                  </button>

                  <button
                    type="button"
                    onClick={handleReset}
                    className="btn primary-btn mx-1 buy-suply-reset-button"
                  >
                    Reset
                  </button>

                  {verrors.product && (
                    <div
                      className="invalid-feedback opacity-0"
                      style={styleError}
                    >
                      {verrors.product}
                    </div>
                  )}
                </div>

              </div>

              <div className="col-xl-10 col-11 buyer-suplier-data-table p-0">
                {loading ? (
                  <div className="text-center d-flex justify-content-center p-3">
                    <Loaders />
                  </div>
                ) : getdata && getdata.length > 0 ? (
                  <>
                    <div className="buyer-suplier-data-table-header">
                      <div className="buyer-supplier-card card-header">
                        <div className="header-border sr-no">Sr No.</div>
                        <div className="header-border hs-code">HS Code</div>
                        <div className="header-border company-name">
                          Company Name
                        </div>
                        <div className="header-border country">Country</div>
                        <div className="header-border trade-type">
                          Trade Type
                        </div>
                        <div className="header-border status">Status</div>
                        <div className="header-border action">Action</div>
                      </div>

                      {getdata &&
                        getdata.map((data, index) => (
                          <div
                            className={`buyer-supplier-card card-data ${index % 2 !== 0 ? "card-data-color" : ""
                              }`}
                            key={index}
                          >
                            <div className="header-border sr-no">
                              {index + 1}
                            </div>
                            <div className="header-border hs-code">
                              {(data.hs_code.length > 15) ? data.hs_code.slice(0, 15 - 3) + '...' : data.hs_code}
                            </div>
                            <div className="header-border company-name">
                              {map(data.company_name.split(" "), capitalize).join(" ")}
                            </div>
                            <div className="header-border country">
                              {map(data.country.split(" "), capitalize).join(" ")}
                            </div>
                            <div className="header-border trade-type">
                              {data.trade_mode === null ? "-" : data.trade_mode}
                            </div>
                            <div className="header-border status">Active</div>
                            <div className="header-border action">
                              {" "}
                              <i
                                className="fa-solid fa-eye"
                                onClick={() => {
                                  setSmallModalShow(true);
                                }}
                              ></i>
                            </div>
                          </div>
                        ))}
                    </div>

                    <div className="find-view-more-btn">
                      <Link
                        onClick={() => {
                          setSmallModalShow(true);
                        }}
                      >
                        <Link className="btn primary-btn">VIEW MORE</Link>
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="text-center p-3">
                    <img src={img} alt="" width={300} className="my-3" />
                  </div>
                )}

                {smallmodalShow && (
                  <RegistrationPopup
                    title=""
                    smallmodalShow={smallmodalShow}
                    setSmallModalShow={setSmallModalShow}
                    onSubmit={handleConfirmLoginClick}
                    okButtonLabel="REGISTER NOW"
                  >
                    <div className="text-center">
                      <h2 className="registor-now">Grow With Us!</h2>
                      <p className="registor-now-text">
                        Register now to get more benefits! Access special
                        features, get personalized recommendations, and connect
                        with a growing community.
                      </p>
                    </div>
                  </RegistrationPopup>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}

export default BuyerSuplierData;
