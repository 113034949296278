const ROUTES = {
    // PERSONAL : '/personal',
    REGISTRATION: '/registration',
    ABOUT: '/about',
    CONTACT: '/contact-us',
    HOME: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgotpassword',
    ACCOUNTDETAIL: '/registration',
    COMPANYREPRESENTATIVE: '/company-representative',
    CONTACTDETAIL: '/contact-detail',
    COMPANY_LIST: '/members',
    RESET_PASSWORD: '/resetpassword',
    REG_SUCCESS: '/registration-success',
    OUR_SERVICES : "/our-services",
    BENEFIT_ADVERTISING : "/benefits/global-advertising",
    BENEFIT_EMAIL_PROMOTIONS : "/benefits/global-email-promotions",
    BENEFIT_LEAD_REPORTS : "/benefits/lead-reports",
    BENEFIT_SEASONAL_PROMOTION : "/benefits/seasonal-promotion",
    BENEFIT_REFERENCE_VERIFICATION : "/benefits/reference-verification",
    BENEFIT_GUARANTEE_PROSPECTIVE_BUSINESS : "/benefits/guarantee-prospective-business",
    BENEFIT_LOGISTICS_TRADE : "/benefits/logistics-trade",
    BENEFIT_TRADE_SAFETY_GUARANTEE : "/benefits/trade-safety-guarantee",
    FIND_BUYER_AND_SUPPLIER:"find-buyer-supplier",
    CONSTRUCTION:"/construction",
    MAINTENANCE:"/maintenance",

};

export default ROUTES;
